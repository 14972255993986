import axios from "axios";
import { apiBaseUrl } from "../ENDPOINT";
import { ObjHeader } from "../Header";

export const GetCompanyDetails = async (payload) => {
  try {
    await axios.get(
      `${apiBaseUrl}/company`,
      { data: { ...payload } },
      { headers: ObjHeader() }
    );
  } catch (error) {
    throw error;
  }
};

export const SetNotes = async (payload) => {
  try {
    await axios.put(
      `${apiBaseUrl}/companyNotes`,
      { ...payload },
      { headers: ObjHeader() }
    );
  } catch (error) {
    throw error;
  }
};

export const AddCompany = async (payload) => {
  try {
    const res = await axios.post(
      `${apiBaseUrl}/company`,
      { ...payload },
      { headers: ObjHeader() }
    )
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const DeleteCompany = async (id) => {
  try {
    await axios.delete(
      `${apiBaseUrl}/company/${id}`,
      { headers: ObjHeader() }
    )
  } catch (error) {
    throw error;
  }
}