import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  useTheme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { WrapperButtonIcon } from '../UI/Wrapper';
import _ from 'lodash';
import CompanyDialog from '../Common/CompanyDialog';
import { handleBookmarkToggle } from '../../helpers/utils/api';
import { ResponsiveTableHeights } from '../../tailwind.custom';

const ResultsTable = ({ tableData, columns }) => {
  const theme = useTheme();
  const [sortedData, setSortedData] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [companyDialog, setOpenDialog] = useState({
    open: false,
    name: '',
    fave: 0,
  });
  const [transformedData, setTransformedData] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const rowsPerPageOptions = [
    10,
    20,
    30
  ]

  useEffect(() => {
    setTransformedData(tableData);
  }, [tableData]);

  useEffect(() => {
    if (transformedData) {
      setSortedData(transformedData.slice(0, rowsPerPage));
    }
  }, [transformedData, rowsPerPage]);

  const handleBookmarkRow = async (event, rowIndex, comp_id, fave) => {
    handleBookmarkToggle(comp_id, fave);
    setTransformedData((prevValue) => {
      const updatedData = prevValue.map((data, index) => {
        if (rowIndex === index) {
          setOpenDialog((data) => ({ ...data, fave: data.fave === 1 ? 0 : 1 }));
          return { ...data, fave: data.fave === 1 ? 0 : 1 };
        }
        return data;
      });
      return updatedData;
    });
  };

  const onClickHandlerSort = (column) => {
    const newSortedData = _.orderBy(
      transformedData,
      [column],
      [sortDirection ? 'asc' : 'desc']
    );
    setSortedData(newSortedData);
    setSortDirection((prevValue) => !prevValue);
    setTransformedData(newSortedData);
  };

  const handleCloseDialog = () =>
    setOpenDialog((data) => ({ ...data, open: false }));

  const handleOpenDialog = (props) => {
    setOpenDialog((data) => ({ ...data, ...props, open: true }));
  };

  const renderRows = (data) => {
    return (
      data &&
      data.map((row, index) => (
        <TableRow
          key={index}
          sx={{
            '&:hover': {
              background: '#F2F2F2',
            },
            fontWeight: 500,
            width: row.width,
          }}
        >
          {columns.map((column, columnIndex) => (
            <TableCell
              key={columnIndex}
              className='text-[12px] py-2 px-4'
              sx={{
                color: '#615D70',
                fontWeight: 500,
                fontSize: '12px',
                width: column.width,
              }}
            >
              {(() => {
                switch (column.key) {
                  case 'job_request':
                    return (
                      <>
                        {row['job_url'].map((url, index) => (
                          <Box className='inline-block w-[85px]' key={index}>
                            <a
                              href={url}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='hover:text-accent-100 px-2'
                            >
                              {`Job Req ${++index}${
                                row['job_url'].length === index ? '' : ','
                              }`}
                            </a>
                          </Box>
                        ))}
                      </>
                    );
                  case 'company':
                    return (
                      <Box className='flex gap-2'>
                        <FontAwesomeIcon
                          size={'lg'}
                          icon={faBookmark}
                          onClick={(event) =>
                            handleBookmarkRow(
                              event,
                              index,
                              row.comp_id,
                              row.fave
                            )
                          }
                          style={{
                            cursor: 'pointer',
                            color: row.fave === 1 ? '#5337B9' : '#e2e2e2',
                          }}
                        />
                        <Box
                          className='inline-block cursor-pointer hover:text-accent-100 hover:font-bold  '
                          key={index}
                          onClick={() => handleOpenDialog({ ...row, index })}
                        >
                          {row[column.key]}
                        </Box>
                      </Box>
                    );
                  default:
                    return (
                      <Box className='inline-block' key={index}>
                        {row[column.key]}
                      </Box>
                    );
                }
              })()}
            </TableCell>
          ))}
        </TableRow>
      ))
    );
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    const startIndex = (page) * rowsPerPage;
    const endIndex = Math.min(
      startIndex + rowsPerPage,
      transformedData.length
    );
    setSortedData(transformedData.slice(startIndex, endIndex));
  };

  const handlerowsPerPageChange = (event) => {
    const newrowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newrowsPerPage);
    setCurrentPage(0);
    const startIndex = 0;
    const endIndex = Math.min(
      startIndex + newrowsPerPage,
      transformedData.length
    );
    setSortedData(transformedData.slice(startIndex, endIndex));
  };

  return (
    <Box className=''>
      <CompanyDialog
        open={companyDialog.open}
        handleClose={handleCloseDialog}
        handleBookmarkRow={handleBookmarkRow}
        setData={setTransformedData}
        setDialog={setOpenDialog}
        {...companyDialog}
      />
      <Paper sx={{ width: '100%' }}>
        <TableContainer
          className={`${ResponsiveTableHeights} + border border-grey-300`}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    className='text-[14px] font-semibold bg-purple-800'
                  >
                    <Box className='flex items-center'>
                      {column.title}
                      {column.key !== 'job_request' && (
                        <WrapperButtonIcon
                          onClickHandler={() => onClickHandlerSort(column.key)}
                          color='accent'
                          className='p-0'
                        >
                          <FontAwesomeIcon icon={faSort} className='pl-2' />
                        </WrapperButtonIcon>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{sortedData && renderRows(sortedData)}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          margin: '5px 0px 5px 5px',
          color: 'red'
        }}
      >
        <TablePagination
          sx={{
            '& .MuiTablePagination-select': {
              minHeight: "auto",
              fontSize: '15px'
            },
            '& .MuiTablePagination-selectLabel': {
              color: theme.palette.secondary.light
            },
            '& .MuiTablePagination-selectIcon': {
              color: theme.palette.accent.main
            },
            '& .MuiTablePagination-toolbar': {
              color: theme.palette.secondary.light
            },
            '& .MuiTablePagination-actions': {
              color: theme.palette.accent.main
            },
          }}
          component='div'
          count={transformedData ? transformedData.length : 0}
          page={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handlerowsPerPageChange}
          rowsPerPageOptions={rowsPerPageOptions}
          showFirstButton
          showLastButton
        />
      </Box>
    </Box>
  );
};

export default ResultsTable;
