import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import SearchTermsComponent from './components/SearchTerms';
import ResultsComponent from './components/Results';
import Companies from './components/Companies';
import Favorites from './components/Favorites';
import IgnoreList from './components/IgnoreList';
import Login from './components/Login';
import SpecificCompany from './components/Companies/SpecificCompany';
import './components/Common/FontAwesome';
import Navigation from './components/Navigation';
import SideNavMoible from './components/Navigation/SideNavMobile';
import { Box } from '@mui/material';
import { isJwtExpired } from './helpers/utils/validation';
import { useSelector } from 'react-redux';
import Admin from './components/Admin/Admin';
import SpecificCompanyAdmin from './components/Admin/Search/SpecificCompanyAdmin';
import Help from './components/Help';

const App = () => {
	const isNavigating = useSelector((state) => state.navigation.isShowing);
	const accessToken = localStorage?.getItem('access_token');

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Navigation />
				<Box className="flex">
					{accessToken && !isJwtExpired(accessToken) && isNavigating && (
						<Box
							className="block lg:hidden xl:hidden 2xl:hidden 3xl:hidden"
							style={{
								position: 'fixed',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								backgroundColor: 'rgba(0, 0, 0, 0.25)',
								zIndex: 999
							}}
						>
							<SideNavMoible />
						</Box>
					)}
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="/search-terms" element={<SearchTermsComponent />} />
						<Route path="/results" element={<ResultsComponent />} />
						<Route path="/companies" element={<Companies />} />
						<Route path="/companies/:id" element={<SpecificCompany />} />
						<Route path="/favorites" element={<Favorites />} />
						<Route path="/favorites/ignore-list" element={<IgnoreList />} />
						<Route path="/admin" element={<Admin />} />
						<Route path="/help" element={<Help />} />
						<Route path="/admin/companies/:id" element={<SpecificCompanyAdmin />} />
					</Routes>
				</Box>
			</Router>
		</ThemeProvider>
	);
};

export default App;
