import { Box } from '@mui/material';
import SideNav from 'components/Admin/SideNav';
import Header from 'components/Admin/Header';
import SpecificCompanyAdmin from './SpecificCompanyAdmin';

export default function Index() {
	return (
		<Box className="flex w-full h-full">
			<SideNav />
			<Box className="flex flex-col w-full">
				<Header />
				<Box className="h-full px-10 py-4">
					<SpecificCompanyAdmin />
				</Box>
			</Box>
		</Box>
	);
}
