import {
	TextField,
	IconButton,
	InputAdornment,
	Box,
	useTheme,
	Typography,
	Tooltip
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

export default function InputComponent({ placeholder, type, ...props }) {
	const [toggle, initToggle] = useState('');

	const handleTogglePassword = () => initToggle((value) => !value);

	return (
		<>
			{(type === 'text' || type === 'number') && (
				<TextField autoComplete="off" placeholder={placeholder} type={type} {...props} />
			)}

			{type === 'password' && (
				<TextField
					autoComplete="off"
					placeholder={placeholder}
					{...props}
					type={toggle ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={handleTogglePassword}>
									{toggle ? (
										<VisibilityOffIcon color="primary" />
									) : (
										<VisibilityIcon color="primary" />
									)}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			)}
		</>
	);
}

export const InputText = ({ className, label, placeholder, ...props }) => {
	return (
		<Box className={`${className} h-[90px]`}>
			<Typography className="w-full text-[16]">{label}</Typography>
			<TextField className="w-full" placeholder={placeholder} type="text" {...props} />
		</Box>
	);
};

export const InputTextAdmin = ({
	className,
	label,
	placeholder,
	inputColor,
	required,
	...props
}) => {
	return (
		<Box className={`${className}`}>
			{label && (
				<Typography variant="h5" className="w-full mb-[16px]">
					{label}
					{required && <span className="text-red-100">*</span>}
				</Typography>
			)}
			<input
				className={`w-full h-[40px] p-2 border border-solid border-gray-300 focus:border-accent rounded-md ${
					inputColor ? inputColor : 'text-gray-700'
				} focus:outline-none focus:ring-1 focus:ring-accent`}
				placeholder={placeholder}
				type="text"
				{...props}
			/>
		</Box>
	);
};

export const InputSearch = ({ className, label, placeholder, ...props }) => {
	return (
		<TextField
			InputProps={{
				autoComplete: 'off',
				startAdornment: (
					<InputAdornment position="start">
						<FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
					</InputAdornment>
				)
			}}
			className={`w-full h-full ${className || ''}`}
			placeholder={placeholder}
			type="text"
			{...props}
		/>
	);
};

export const InputMultiline = ({ className, label, placeholder, ...props }) => {
	return (
		<Box className={`${className} h-[90px]`}>
			<Typography className="w-full mb-1">{label}</Typography>
			<TextField
				className="w-full h-full"
				placeholder={placeholder}
				type="text"
				multiline
				{...props}
			/>
		</Box>
	);
};

export const InputSearchTerms = ({
	value,
	fieldDisabled = false,
	inputDisabled = false,
	handleEditMode,
	handleOnChange,
	handleClear,
	name,
	error,
	helperText,
	size,
	status,
	redOutline,
	...props
}) => {
	const theme = useTheme();
	const sx = {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		'& .MuiInputBase-root': {
			borderRadius: '5px',
			height: '48px',
			fontSize: '14px',
			fontWeight: 400,
			minWidth: '100%'
		},
		'@media only screen and (min-width: 600px)': {
			width: size ? size : '260px',
			'& .MuiInputBase-root': {
				minWidth: size ? size : '260px',
				fontSize: '18px'
			}
		},
		'& .MuiInputBase-input.Mui-disabled': {
			WebkitTextFillColor: '#808080',
			fontWeight: 350,
			color: '#808080'
		},
		'& input': {
			textAlign: 'center'
		},
		'& .MuiInputBase-input': {
			textAlign: 'center',
			color: '#808080', // Default color
			'&:hover': {
				color: '#000000' // Change color on hover if needed
			}
		},
		'& .Mui-focused .MuiInputBase-input': {
			color: '#000000' // Change color when focused
		},
		'&:hover': {
			cursor: 'pointer',
			'& .MuiInputBase-input.Mui-disabled': {
				WebkitTextFillColor: '#5337B9',
				fontWeight: 350,
				color: '#808080'
			}
		},
		'& input::placeholder': {
			padding: '0',
			color: theme.palette.primary.main
		}
	};

	if (status === 'Disabled' || redOutline) {
		sx['& .MuiOutlinedInput-root'] = {
			'& fieldset': {
				border: '1px solid',
				borderColor: theme.palette.red.main,
				color: '#444444'
			},
			'&:hover fieldset': {
				borderColor: theme.palette.red.main
			}
		};

		sx['& .MuiInputBase-input'] = {
			color: theme.palette.black.main
		};
	}

	return (
		<TextField
			sx={sx}
			value={value}
			onChange={handleOnChange}
			name={name}
			error={error}
			placeholder="Add Search Term"
			{...props}
			InputProps={{
				autoComplete: 'off',
				endAdornment: (
					<InputAdornment position="end">
						<Box className="flex gap-x-3">
							{error && (
								<Box>
									<IconButton name={name} sx={{ padding: 0 }}>
										<Box className="h-full">
											<Tooltip
												title={helperText}
												arrow
												componentsProps={{
													tooltip: {
														sx: {
															bgcolor: '#CD1515',
															'& .MuiTooltip-arrow': {
																color: '#CD1515'
															}
														}
													}
												}}
											>
												<FontAwesomeIcon icon={faCircleExclamation} className="text-red-100" />
											</Tooltip>
										</Box>
									</IconButton>
								</Box>
							)}
						</Box>
					</InputAdornment>
				)
			}}
		/>
	);
};
