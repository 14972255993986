import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const withAuth = (WrappedComponent) => {
  const WithAuth = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      // Check access token from local storage or your authentication mechanism
      const accessToken = localStorage?.getItem("access_token");

      if (!accessToken) {
        // Redirect to home page if access token does not exist
        navigate("/");
      }
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  return WithAuth;
};

export default withAuth;
