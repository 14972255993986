import { isJwtExpired } from '../helpers/utils/validation';

export const prepareHeaders = (headers) => {
  try {
    const token = localStorage?.getItem('access_token');
    if (token) {
      if (!isJwtExpired(token)) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    }
    return headers;
  } catch (error) {
    localStorage.clear();
  }
};

export const ObjHeader = () => {
  try {
    const token = localStorage?.getItem('access_token');
    if (token) {
      if (!isJwtExpired(token)) {
        return { Authorization: `Bearer ${token}` };
      }
    }
  } catch (error) {
    localStorage.clear();
  }
};
