import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
	ContactUs,
	FAQs,
	GettingStarted,
	ManagingAccount,
	ManagingFavorites,
	SearchingForCompanies,
	UsingSearchTerms,
	ViewingResults
} from './HelpItems';

const Index = () => {
	const [clickedIndex, setClickedIndex] = useState(null);

	const handleItemClick = (index) => {
		setClickedIndex(index);
	};

	const menuItems = [
		'Getting Started',
		'Managing Your Account',
		'Using Search Terms',
		'Viewing Results',
		'Managing Favorites',
		'Searching for Companies',
		'FAQs',
		'Contact Us'
	];

	const components = [
		<GettingStarted />,
		<ManagingAccount />,
		<UsingSearchTerms />,
		<ViewingResults />,
		<ManagingFavorites />,
		<SearchingForCompanies />,
		<FAQs />,
		<ContactUs />
	];

	return (
		<Box className="flex flex-col w-full">
			<Box className="relative h-[400px] overflow-hidden">
				<Box
					className="z-10 absolute inset-0 bg-black-100 bg-opacity-40 transition-opacity"
					aria-hidden="true"
				></Box>
				<Box className="w-full h-full">
					<img
						src="https://images.pexels.com/photos/218863/pexels-photo-218863.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
						alt="Help background"
						className="w-full h-full object-cover relative"
					/>
				</Box>
				<Box className="z-20 absolute inset-0 flex items-center justify-center">
					<Box className="flex flex-col bg-white bg-opacity-75 p-4 gap-y-[32px]">
						<Typography variant="h1" className="text-center text-white-100">
							Help Center
						</Typography>
						<Typography variant="h4" className="text-center text-white-100">
							Welcome to the OnlineLeads Help Center! <br />
							Here you'll find all the information you need to make the most out of our platform.
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box className="h-auto flex">
				<Box className="pt-[48px] px-[72px] w-[30%]">
					<Typography variant="h3" className="mb-[24px]">
						Table of Contents
					</Typography>
					<Box className="flex flex-col gap-y-[16px] mb-[16px]">
						{menuItems.map((item, index) => (
							<Typography
								key={index}
								variant="title2"
								className="text-purple-100 font-medium cursor-pointer"
								onClick={() => handleItemClick(index)}
							>
								&bull; <span className={clickedIndex === index ? 'underline' : ''}>{item}</span>
							</Typography>
						))}
					</Box>
				</Box>
				<Box className="h-full w-[1%]">
					<Box className="h-full w-[1px] bg-grey-300" />
				</Box>
				<Box className="w-[69%] pt-[48px] px-[72px]">
					{components.map(
						(component, index) => clickedIndex === index && <div key={index}>{component}</div>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default Index;
