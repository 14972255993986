import React from 'react';
import DataTableFavorites from './DataTableFavorites';

export default function Table({ results, selectedSearchResult, setSelectedSearchResult }) {
	const columns = [
		{ title: 'Company Name', key: 'company' },
		{ title: 'Notes', key: 'notes' }
	];

	return (
		<DataTableFavorites
			tableData={results}
			columns={columns}
			selectedSearchResult={selectedSearchResult}
			setSelectedSearchResult={setSelectedSearchResult}
		/>
	);
}
