import {
  Button,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { WrapperButtonIcon } from './Wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faBan } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmarked } from '@fortawesome/free-regular-svg-icons';

export default function CustomButton({
  TypographyComponent,
  typographyProps,
  label,
  width,
  ...props
}) {
  return (
    <Button
      {...props}
      sx={{ width: width ? width : 'auto', height: '100%', textTransform: 'none' }}
    >
      {TypographyComponent ? (
        <TypographyComponent {...typographyProps}> {label}</TypographyComponent>
      ) : (
        label
      )}
    </Button>
  );
}

export const IconButton = ({
  label,
  icon,
  iconProps,
  TypographyComponent,
  typographyProps,
  ...props
}) => {
  return (
    <WrapperButtonIcon {...props}>
      <FontAwesomeIcon icon={icon} {...iconProps} />
      {TypographyComponent ? (
        <TypographyComponent {...typographyProps}>{label}</TypographyComponent>
      ) : (
        label
      )}
    </WrapperButtonIcon>
  );
};

export const ButtonLinkMobile = ({ label, ...props }) => {
  return <Button {...props}>{label}</Button>;
};

export const ButtonLink = ({ label, destination, handleOnClick, ...props }) => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <>
      {/* Checks if the label is Companies
        if true, use ButtonGroup
    */}
      <Button
        onClick={() => handleOnClick(destination)}
        variant='title3'
        disableRipple
        sx={{
          fontWeight: 500,
          mx: 2,
          textTransform: 'capitalize',
          color: '#020104',
          '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.primary.main,
          },
          border: 'none',
          boxShadow: 'none',
          ...(location.pathname === destination && {
            position: 'relative',
            color: theme.palette.primary.main,
            '&:after': {
              content: '""',
              position: 'absolute',
              bottom: '-15px',
              width: '100%',
              borderBottom: `2px solid ${theme.palette.primary.main}`,
            },
          }),
        }}
        {...props}
      >
        {label}
      </Button>
    </>
  );
};

export const ButtonFilter = ({ label, active, ...props }) => {
  return (
    <Button
      variant={active ? 'filter-active' : 'filter'}
      {...props}
      className='h-[32px] text-[10px] xxl:h-[40px] xxl:text-[14px]'
    >
      {label}
    </Button>
  );
};

export const ButtonFavorite = ({
  handleBookmarkRow,
  fave,
  styles,
  size = 'xl',
}) => {
  return (
    <WrapperButtonIcon
      sx={{ color: fave === 1 ? '#5337B9' : '#e2e2e2', ...styles }}
      onClick={(event) => handleBookmarkRow(event)}
    >
      <FontAwesomeIcon
        color='#5337B9'
        size={size}
        icon={fave === 1 ? faBookmark : faBookmarked}
      />
    </WrapperButtonIcon>
  );
};

export const ButtonIgnore = ({
  handleIgnoreRow,
  ignore,
  styles,
  size = 'xl',
}) => {
  return (
    <WrapperButtonIcon
      sx={{ color: ignore === 1 ? '#5337B9' : '#e2e2e2', ...styles }}
      onClick={(event) => handleIgnoreRow(event)}
    >
      <FontAwesomeIcon
        color='#5337B9'
        size={size}
        icon={ignore === 1 ? faBan : faBan}
      />
    </WrapperButtonIcon>
  );
};
