import ResultsTable from "./Table";
import withAuth from "../../helpers/config/Auth/withAuth";

function Results({ results, isLoading, isError }) {
  const columns = [
    { title: "Search Terms", key: "search_terms", width: "15%" },
    { title: "Company", key: "company", width: "10%" },
    { title: "Job Request Link", key: "job_request", width: "40%" },
    { title: "Latest Date", key: "search_date", width: "15%" },
    { title: "First Date", key: "initial_date", width: "15%" },
  ];
  return (
    <>
      {!isLoading && !isError > 0 && (
        <ResultsTable tableData={results} columns={columns} />
      )}
    </>
  );
}

export default withAuth(Results);
