import { Box, Typography } from '@mui/material';

export default function CompanyDetails({ company }) {
	return (
		<Box className="grid grid-cols-2 gap-y-[16px] w-[30%] my-[24px] ml-[60px]">
			<Typography variant="h4">Company ID: </Typography>
			<Typography variant="h4" className="mb-10 text-accent-100">
				{company?.comp_id}
			</Typography>

			<Typography variant="h5">Jobsite ID: </Typography>
			<Typography variant="paragraph">{company?.jobsite_id}</Typography>

			<Typography variant="h5">Website: </Typography>
			<Typography variant="paragraph">{company?.website ? company?.website : 'N/A'}</Typography>

			<Typography variant="h5">Notes: </Typography>
			<Typography variant="paragraph">{company?.notes ? company?.notes : 'N/A'}</Typography>
		</Box>
	);
}
