import React from "react";
import { IconButton, Typography } from "@mui/material";
import { jwtParser } from "../../helpers/utils/parser";

export default function ProfileInitials({ handleOnClick }) {
  const token = localStorage.getItem("access_token");
  const { given_name, family_name } = jwtParser(token);
  const initials =
    given_name &&
    family_name &&
    given_name.charAt(0).concat(family_name.charAt(0));
  return (
    <IconButton
      className="relative rounded-full overflow-hidden transition duration-300 ease-in-out "
      onClick={handleOnClick}
      name="Profile"
    >
      <img
        src="/asset/ProfileCircleBackground.svg"
        alt="profile-background"
        sx={{ width: "100%", height: "auto" }}
      />
      <Typography
        variant="body1"
        component="span"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        {initials ? initials : "--"}
      </Typography>
    </IconButton>
  );
}
