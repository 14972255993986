import axios from "axios";
import { apiBaseUrl } from "../ENDPOINT";
import { ObjHeader } from "../Header";

export const SetFavorites = async (payload) => {
  try {
    await axios.post(
      `${apiBaseUrl}/favorites`,
      { ...payload },
      { headers: ObjHeader() }
    );
  } catch (error) {
    throw error;
  }
};

export const RemoveFavorites = async (payload) => {
  try {
    await axios.delete(`${apiBaseUrl}/favorites`, {
      data: { ...payload },
      headers: ObjHeader(),
    });
  } catch (error) {
    throw error;
  }
};

export const GetFavorites = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/favorites`, {
      headers: ObjHeader(),
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
