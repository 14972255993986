import React from "react";
import { Box, Typography } from "@mui/material";
import Settings from "../Navigation/Settings";
export default function Header() {
  return (
    <Box className="flex w-full justify-between items-center h-[80px] shadow-md px-6">
      <Typography variant="title2">Companies</Typography>
      <Settings />
    </Box>
  );
}
