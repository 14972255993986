import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import Button from '../../../UI/Button';
import { WrapperButtonIcon } from '../../../UI/Wrapper';

export default function DeleteDialog({ handleCloseDeleteDialog, company, deleting, handleDelete }) {
	return (
		<Box className="fixed z-10 inset-0 overflow-y-auto">
			<Box className="flex items-center justify-center min-h-screen">
				<Box
					className="fixed z-10 inset-0 bg-black-100 bg-opacity-50 transition-opacity"
					aria-hidden="true"
				></Box>
				<Box className="z-30 bg-white-100 relative p-8 shadow-xl w-[50%]">
					<Box className="flex justify-end items-center mb-8">
						<WrapperButtonIcon className="cursor-default">
							<FontAwesomeIcon
								icon={faXmark}
								style={{
									cursor: 'pointer',
									color: '#71717A',
									fontSize: '32px'
								}}
								onClick={handleCloseDeleteDialog}
							/>
						</WrapperButtonIcon>
					</Box>
					<Box className="flex flex-col justify-center items-center gap-y-[72px]">
						<Box className="flex flex-col gap-y-[20px] justify-center items-center">
							<WrapperButtonIcon className="cursor-default">
								<FontAwesomeIcon
									icon={faQuestionCircle}
									style={{
										color: '#3E2989',
										fontSize: '120px'
									}}
								/>
							</WrapperButtonIcon>
							<Typography variant="title1" className="text-center">
								Are you sure you want to delete <span className="text-accent-100">{company}</span>?
							</Typography>
							<Typography variant="paragraph" className="text-center">
								This action cannot be undone. Proceed only if you are certain.
							</Typography>
						</Box>
						<Box className="flex justify-center gap-5">
							<Button
								className="text-[16px] xl:text-[18px]ext-[18px]"
								label="Cancel"
								variant="secondary"
								onClick={handleCloseDeleteDialog}
							/>
							<Button
								className="text-white-100 text-[16px] xl:text-[18px]"
								label={deleting ? 'Deleting...' : 'Delete'}
								variant="primary"
								onClick={handleDelete}
								disabled={deleting}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
