import React from "react";
import {
  Paper,
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useState } from 'react';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WrapperButtonIcon } from "../UI/Wrapper";
import { useGetSearchTermsQuery } from "../../features/searchTerms";

export default function OverlayFilter({
    visibility,
    closeOverlayFilter,
    setTransformedData,
    results
  }) {
  const { data: currentSearchTerms} = useGetSearchTermsQuery();
  const initialCheckedSearchTerms = currentSearchTerms?.reduce((acc, { combined_search_term }) => {
    acc[combined_search_term] = true; // Set the default value to true
    return acc;
  }, {});
  const [checkedSearchTerms, setCheckedSearchTerms] = useState(initialCheckedSearchTerms);

  const onClickFilterHandler = (combined_search_term) => {
    setCheckedSearchTerms((prevChecked) => {
      const updatedChecked = {
        ...prevChecked,
        [combined_search_term]: !prevChecked[combined_search_term],
      };
      
      const filteredData = results.filter(
        (data) => updatedChecked[data.search_terms]
      );
  
      setTransformedData(filteredData);
      return updatedChecked;
    });
  };

  return (
    <>
      {visibility && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              zIndex: 998,
            }}
          />
          <Paper
            sx={{
              width: "100%",
              position: "fixed",
              bottom: "0px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 999,
              padding: "16px",
              transition: "0.3s ease",
              borderRadius: "24px 24px 0px 0px",
              height: "300px",
            }}
          >
            <Box className="flex items-center justify-between">
              <Box></Box>
              <Typography variant="title3" className="text-purple-300">
                Search Terms
              </Typography>
              <WrapperButtonIcon
                className="text-black-100"
                onClick={closeOverlayFilter}
              >
                <FontAwesomeIcon icon={faTimes} />
              </WrapperButtonIcon>
            </Box>

            <FormGroup>
              {currentSearchTerms &&
                currentSearchTerms.map(({ combined_search_term }) => (
                  <FormControlLabel
                    key={combined_search_term}
                    control={<Checkbox checked={checkedSearchTerms[combined_search_term] || false} />}
                    label={combined_search_term}
                    onChange={() => {onClickFilterHandler(combined_search_term)}}
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "24px",
                      },
                    }}
                  />
                ))}
            </FormGroup>
          </Paper>
        </>
      )}
    </>
  );
}
