import { RemoveFavorites, SetFavorites } from "../../features/api/Favorites";

export const handleBookmarkToggle = async (comp_id, fave) => {
  try {
    if (fave === 1) {
      await RemoveFavorites({ comp_id });
    } else {
      await SetFavorites({ comp_id });
    }
  } catch (error) {
    console.error(error);
  }
};
