import SearchTerms from "./SearchTerms";
import { useGetSearchTermsQuery } from "../../features/searchTerms";
import Loading from "../Common/Loading";
import { Box } from "@mui/material";

export default function SearchTermsComponent() {
  const { isLoading, isError } = useGetSearchTermsQuery();

  return (
    <>
      <Box className="flex flex-col items-center h-full w-full mx-4 xl:mx-[32px] mt-[32px]">
        {isLoading && <Loading />}
        {!isLoading && !isError && <SearchTerms />}
      </Box>
    </>
  );
}
