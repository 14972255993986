import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../ENDPOINT";
import { prepareHeaders } from "../Header";

export const searchTermsApi = createApi({
  reducerPath: "searchTermsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getSearchTerms: builder.query({
      query: () => ({
        url: "/searchTerm",
        method: "GET",
      }),
      transformResponse: (response) => {
        const transformedResponse = response.map((term) => {
          if (term.extra_term1 !== "" || term.extra_term2 !== "") {
            let combinedTerms;
            const and = "<and>";
            if (term.extra_term1 !== "") {
              combinedTerms = `${term.search_term} ${and} ${term.extra_term1}`;
            }

            if (term.extra_term2 !== "") {
              combinedTerms = `${combinedTerms} ${and} ${term.extra_term2}`;
            }
            return { ...term, combined_search_term: combinedTerms };
          }
          return { ...term, combined_search_term: term.search_term };
        });
        return transformedResponse;
      },
    }),
  }),
});

export const { useGetSearchTermsQuery } = searchTermsApi;
