import axios from 'axios';
import { apiBaseUrl } from '../ENDPOINT';
import { ObjHeader } from '../Header';

export default async function SaveSearchTerms(payload) {
	try {
		const response = await axios.put(
			`${apiBaseUrl}/searchTerm`,
			{ ...payload },
			{ headers: ObjHeader() }
		);
		return response.data; // Return the response data
	} catch (error) {
		throw error;
	}
}
