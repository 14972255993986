import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import { InputTextAdmin } from '../../UI/Input';
import Button from '../../UI/Button';
import { useState } from 'react';
import { AddCompany } from 'features/api/Company';
import Notification from 'components/Common/Notification';
import { WrapperButtonIcon } from '../../UI/Wrapper';
import ScrapeSwitch from '../../UI/Switch';

export default function AddDialog({
	handleCloseAddDialog,
	handleOpenSuccessDialog,
	setNewCompanyId
}) {
	const [companyNameError, setCompanyNameError] = useState(false);
	const [websiteError, setWebsiteError] = useState(false);
	const [companyName, setCompanyName] = useState('');
	const [jobsiteId, setJobsiteId] = useState('');
	const [website, setWebsite] = useState('');
	const [jobsiteParser, setJobsiteParser] = useState('');
	const [jobsiteUrl, setJobsiteUrl] = useState('');
	const [scrapeEnabled, setScrapeEnabled] = useState(false);
	const [adding, setAdding] = useState(false);
	const [notification, setNotification] = useState({
		message: '',
		type: '',
		open: false
	});

	const handleAdd = async () => {
		if (!companyName) {
			setCompanyNameError(true);
		}
		if (!website) {
			setWebsiteError(true);
		}

		const payload = {
			comp_name: companyName,
			jobsite_id: jobsiteId,
			website: website,
			jobsite_parser: jobsiteParser,
			scrape_enabled: scrapeEnabled,
			jobsite_url: jobsiteUrl
		};

		if (companyName && website) {
			setAdding(true);
			try {
				const data = await AddCompany(payload);
				setNewCompanyId(data.comp_id);

				handleCloseAddDialog();
				handleOpenSuccessDialog();
			} catch (error) {
				setNotification({
					message: `${error.response?.data ? error.response?.data : error}`,
					type: 'error',
					open: true
				});
			}
			setAdding(false);
		}
	};

	const handleScrapeSwitch = () => {
		setScrapeEnabled((value) => !value);
	};

	return (
		<Box className="fixed z-10 inset-0 overflow-y-auto">
			<Notification
				message={notification.message}
				type={notification.type}
				open={notification.open}
				setOpen={setNotification}
			/>
			<Box className="flex items-center justify-center min-h-screen">
				<Box
					className="fixed z-10 inset-0 bg-black-100 bg-opacity-50 transition-opacity"
					aria-hidden="true"
				></Box>
				<Box className="z-30 bg-white-100 relative p-8 shadow-xl w-[65%]">
					<Box className="flex justify-between items-center mb-8">
						<Typography variant="title2">Add Company</Typography>
						<WrapperButtonIcon className="cursor-default" onClick={handleCloseAddDialog}>
							<FontAwesomeIcon
								icon={faXmark}
								style={{
									cursor: 'pointer',
									color: '#71717A',
									fontSize: '32px'
								}}
							/>
						</WrapperButtonIcon>
					</Box>
					<Box className="flex flex-col">
						<Box className="flex flex-col gap-y-[16px] mb-8">
							<Box>
								<InputTextAdmin
									className="h-[90px]"
									label="Company Name"
									required={true}
									value={companyName}
									onChange={(event) => {
										setCompanyName(event.target.value);
										setCompanyNameError(false);
									}}
								/>
								{companyNameError && (
									<Typography variant="h7" className="text-red-100">
										This field is required.
									</Typography>
								)}
							</Box>
							<Box>
								<InputTextAdmin
									className="h-[90px]"
									label="Jobsite ID"
									required={false}
									value={jobsiteId}
									onChange={(event) => {
										setJobsiteId(event.target.value);
									}}
								/>
							</Box>
							<Box className="flex items-center justify-center gap-x-[24px]">
								<Box className="w-full">
									<InputTextAdmin
										className="h-[90px]"
										label="Website"
										required={true}
										value={website}
										onChange={(event) => {
											setWebsite(event.target.value);
											setWebsiteError(false);
										}}
									/>
									{websiteError && (
										<Typography variant="h7" className="text-red-100">
											This field is required.
										</Typography>
									)}
								</Box>
								<Box className="w-full">
									<InputTextAdmin
										className="h-[90px]"
										label="Jobsite Parser"
										required={false}
										value={jobsiteParser}
										onChange={(event) => {
											setJobsiteParser(event.target.value);
										}}
									/>
								</Box>
							</Box>
							<Box className="flex items-center justify-center gap-x-[24px]">
								<Box className="w-full">
									<InputTextAdmin
										className="h-[90px]"
										label="Jobsite URL"
										required={false}
										value={jobsiteUrl}
										onChange={(event) => {
											setJobsiteUrl(event.target.value);
										}}
									/>
								</Box>
								<Box className="w-full my-[8px]">
									<Typography variant="h5" className="w-full mb-[16px]">
										Scrape Enabled
									</Typography>
									<ScrapeSwitch
										className="mb-2"
										scrapeEnabled={scrapeEnabled ? true : false}
										disabled={false}
										handleScrapeSwitch={handleScrapeSwitch}
									/>
								</Box>
							</Box>
						</Box>
						<Box className="flex justify-center gap-5">
							<Button
								className="text-[16px] xl:text-[18px]ext-[18px]"
								label="Cancel"
								variant="secondary"
								onClick={handleCloseAddDialog}
							/>
							<Button
								className="text-white-100 text-[16px] xl:text-[18px]"
								label={adding ? 'Adding...' : 'Add'}
								variant="primary"
								onClick={handleAdd}
								disabled={adding}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
