import { WrapperButtonIcon } from '../UI/Wrapper';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Button from '../UI/Button';
import Cookies from 'js-cookie';

export default function TipsDialog({ handleCloseTipsDialog }) {
	const [checked, setChecked] = useState(false);
	const [screen, setScreen] = useState('');
	const [heading, setHeading] = useState('');
	const [title, setTitle] = useState('');
	const [show, setShow] = useState('');

	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;
		setChecked(isChecked);
		Cookies.set('showTips', !isChecked, { expires: 365 });
	};

	useEffect(() => {
		const updateScreen = () => {
			const width = window.innerWidth;
			if (width < 640) {
				setScreen('xs');
			} else if (width < 768) {
				setScreen('sm');
			} else if (width < 1024) {
				setScreen('md');
			} else if (width < 1280) {
				setScreen('lg');
			} else if (width < 1536) {
				setScreen('xl');
			} else if (width < 1890) {
				setScreen('2xl');
			}
		};

		updateScreen();
		window.addEventListener('resize', updateScreen);

		return () => {
			window.removeEventListener('resize', updateScreen);
		};
	}, []);

	useEffect(() => {
		const variantMapping = {
			xs: { heading: 'h4', title: 'paragraph', show: 'paragraph' },
			sm: { heading: 'h4', title: 'paragraph', show: 'paragraph' },
			md: { heading: 'h4', title: 'paragraph', show: 'paragraph' },
			lg: { heading: 'h3', title: 'title3', show: 'paragraph' },
			xl: { heading: 'h3', title: 'title3', show: 'paragraph' },
			'2xl': { heading: 'h2', title: 'title2', show: 'title3' }
		};

		const variants = variantMapping[screen] || {
			heading: 'text-xl',
			title: 'text-lg',
			show: 'text-md'
		};
		setHeading(variants?.heading);
		setTitle(variants?.title);
		setShow(variants?.show);

		console.log(screen);
	}, [screen]);

	return (
		<Box className="fixed z-50 inset-0 overflow-y-scroll">
			<Box className="flex items-center justify-center min-h-screen">
				<Box
					className="fixed z-30 inset-0 bg-black-100 bg-opacity-50 transition-opacity"
					aria-hidden="true"
				></Box>
				<Box className="z-30 bg-purple-300 relative p-[16px] m-[16px] shadow-xl w-[96%]">
					<Box className="flex justify-end items-center mb-0">
						<WrapperButtonIcon className="cursor-default" onClick={handleCloseTipsDialog}>
							<FontAwesomeIcon
								icon={faXmark}
								style={{
									cursor: 'pointer',
									color: '#FFFFFF',
									fontSize: '32px'
								}}
							/>
						</WrapperButtonIcon>
					</Box>
					<Box className="flex flex-col justify-between text-white-100 px-[40px]">
						<Box className="flex justify-center mb-[24px]">
							<Typography variant={heading}>
								Welcome to
								<img
									src="/logo/logo-white.svg"
									alt="icon"
									style={{
										display: 'inline-block',
										verticalAlign: 'middle',
										width: '80px',
										marginLeft: '24px',
										marginRight: '4px'
									}}
								/>
								<b>OnlineLeads!</b>
							</Typography>
						</Box>
						<Box>
							<Box className="mb-[16px]">
								<Typography variant={title}>
									We're excited to have you here. To help you get started, here are some quick tips
									& tricks:
								</Typography>
							</Box>
							<Box className="mb-[16px] flex flex-col">
								<Typography variant={title}>
									<b>Step 1: Add Your Preferred Search Terms</b>
								</Typography>
								<Typography variant={title}>
									&bull; <i>Main Search Term</i>: Add your main search term on the search terms page
									(minimum of 1 and maximum of 5).
								</Typography>
								<Typography variant={title}>
									&bull; <i>Extra Terms</i>: Add 1-2 extra terms related to your main search term to
									filter the results more precisely.
								</Typography>
							</Box>
							<Box className="mb-[16px] flex flex-col">
								<Typography variant={title}>
									<b>Step 2: View Results</b>
								</Typography>
								<Typography variant={title}>
									&bull; <i>Results Page</i>: Navigate to the results page to see the list of
									companies and job links that match your search terms.
								</Typography>
								<Typography variant={title}>
									&bull; <i>Filter Results</i>: Click the "Search Terms" button at the top of the
									table to filter your results.
								</Typography>
								<Typography variant={title}>
									&bull; <i>Favorites</i>: Bookmark your favorite companies by clicking the
									favorites button. Click on the company name to see more details and add notes.
								</Typography>
							</Box>

							<Box className="mb-[16px] flex flex-col">
								<Typography variant={title}>
									<b>Step 3: Manage Your Favorites</b>
								</Typography>
								<Typography variant={title}>
									&bull; <i>Favorites Page</i>: Go to the favorites page to view all the companies
									you’ve bookmarked.
								</Typography>
							</Box>

							<Box className="mb-[32px] flex flex-col">
								<Typography variant={title}>
									<b>Step 4: Search for Companies</b>
								</Typography>
								<Typography variant={title}>
									&bull; <i>Companies Page</i>: Use the search bar on the companies page to look for
									specific companies.
								</Typography>
								<Typography variant={title}>
									&bull; <i>Company Details</i>: Click on a company to be redirected to its page,
									where you can see more details and the list of jobs under that company.
								</Typography>
							</Box>
							<Box className="flex flex-col items-center">
								<Button
									onClick={handleCloseTipsDialog}
									label="Explore Now"
									variant="secondary"
									className="w-[184px] h-[56px] text-purple-100 font-medium mb-[16px]"
								/>
								<Box className="flex items-center">
									<Checkbox
										className="text-white-100"
										checked={checked}
										onChange={handleCheckboxChange}
									></Checkbox>
									<Typography variant={show} className="font-[350]">
										Do not show this again
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
