import React from "react";
import { Typography } from "@mui/material";

const TypographyComponent = ({ children, className, ...props }) => {
  return (
    <Typography className={`${className}`} {...props}>
      {children}
    </Typography>
  );
};

export const Headline1 = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[72px] font-[700] ${className}`}
    {...props}
  />
);

export const Headline2 = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[51px] font-[500] ${className}`}
    {...props}
  />
);

export const Headline3 = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[36px] font-[400] ${className}`}
    {...props}
  />
);

export const Headline4 = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[25px] font-[400] ${className}`}
    {...props}
  />
);

export const Headline5 = ({ children, className, ...props }) => (
  <TypographyComponent
    className={`text-[18px] font-[600] ${className}`}
    {...props}
  >
    {children}
  </TypographyComponent>
);

export const Headline6 = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[16px] font-[500] tracking-wider ${className}`}
    {...props}
  />
);

export const Headline7 = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[14px] font-[600] tracking-wider ${className}`}
    {...props}
  />
);

export const Title1 = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[36px] font-[500] ${className}`}
    {...props}
  />
);

export const Title2 = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[25px] font-[500] ${className}`}
    {...props}
  />
);

export const Title3 = ({ children, className, ...props }) => (
  <TypographyComponent
    className={`text-[18px] font-[500] ${className}`}
    {...props}
  >
    {children}
  </TypographyComponent>
);

export const FilteringLink = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[18px] font-[350] tracking-wide ${className}`}
    {...props}
  />
);

export const SpecialLink = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[14px] font-[350] tracking-wide ${className}`}
    {...props}
  />
);

export const TextLink = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[14px] font-[350] tracking-wide ${className}`}
    {...props}
  />
);

export const TextMuted = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[18px] font-[350] italic ${className}`}
    {...props}
  />
);

export const TextCaption = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[14px] font-[350] italic ${className}`}
    {...props}
  />
);

export const TextBreadcrumbs = ({ children, className, ...props }) => (
  <TypographyComponent
    className={`text-[14px] font-[350]  ${className}`}
    {...props}
  >
    {children}
  </TypographyComponent>
);

export const TextMicro = ({ className, ...props }) => (
  <TypographyComponent
    className={`text-[14px] font-[350] italic ${className}`}
    {...props}
  />
);

export const Paragraph = ({ children, className, ...props }) => (
  <TypographyComponent
    className={`text-[18px] font-[350] ${className}`}
    {...props}
  >
    {children}
  </TypographyComponent>
);
