import React, { useEffect } from "react";
import Footer from "./Footer";

const ZohoFormEmbed = () => {
  useEffect(() => {
    const embedZohoForm = () => {
      try {
        const f = document.createElement("iframe");
        f.src =
          "https://forms.zohopublic.com/wjm/form/ContactUs2/formperma/dOWQP6M1vmS9OX6AXvoBnHIkglzRTRKfSGAs-lLI9qQ?zf_rszfm=1";
        f.style.border = "none";
        f.style.height = "800px";
        f.style.width = "100%";
        f.style.transition = "all 0.5s ease";

        const d = document.getElementById(
          "zf_div_dOWQP6M1vmS9OX6AXvoBnHIkglzRTRKfSGAs-lLI9qQ"
        );
        d.appendChild(f);

        f.onload = () => {
          //console.log("Zoho Form iframe loaded");
        };

        f.onerror = (error) => {
          console.error("Zoho Form iframe loading error:", error);
        };
      } catch (e) {
        console.error("Error embedding Zoho Form:", e);
      }
    };

    embedZohoForm();
  }, []);

  return (
    <>
      <div
        id="zf_div_dOWQP6M1vmS9OX6AXvoBnHIkglzRTRKfSGAs-lLI9qQ"
        style={{ width: "100%" }}
      ></div>
      <Footer />
    </>
  );
};

export default ZohoFormEmbed;
