import { Box, Typography } from "@mui/material";
import Button from "../UI/Button";

export default function Content({ handleGetStarted }) {
  return (
    <Box className="flex items-center justify-center w-full h-full">
      <Box className="flex flex-col justify-evenly w-full gap-y-3 px-5 lg:gap-y-5 lg:w-2/5">
        <Typography
          variant="h1"
          className="text-[51px] leading-[72px] md:leading-normal lg:leading-normal"
        >
          Navigating <span className="text-accent-100">Job Trends</span> with
          Data Insights
        </Typography>
        <Typography
          variant="h4"
          className="text-grey-100 weight-[350] text-[15px]"
        >
          Harness the Power of Data-Driven Job Trends for Business Growth and
          Personal Success. Join now to stay ahead in a dynamic world!{" "}
        </Typography>
        <Box className="h-[60px] w-[180px] lg:w-[250px]">
          <Button
            variant="primary"
            label="Get Started"
            onClick={handleGetStarted}
          />
        </Box>
        <Box className="w-full h-[320px] lg:hidden">
          <img
            src="/asset/login-working.svg"
            alt="working"
            className="h-[full] w-full"
          />
        </Box>
      </Box>
      <Box className="hidden lg:block lg:w-2/5 lg:h-[540px]">
        <img
          src="/asset/login-working.svg"
          alt="working"
          className="h-full w-full"
        />
      </Box>
    </Box>
  );
}
