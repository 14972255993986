import { Box, Typography } from "@mui/material";
export default function Footer() {
  return (
    <Box className="flex items-center justify-center border-t border-grey-300 h-[80px] w-full">
      <Typography className="text-purple-500 text-xsm px-8">
        © 2023 OnlineLeads
      </Typography>
    </Box>
  );
}
