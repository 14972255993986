import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowLeft,
	faPen,
	faTrash,
	faXmarkCircle,
	faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { useGetCompanyQuery } from 'features/specificCompany';
import Button from '../../../UI/Button';
import { WrapperButtonIcon } from '../../../UI/Wrapper';
import { Headline6 } from '../../../UI/Typography';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { InputTextAdmin } from '../../../UI/Input';
import DeleteDialog from './DeleteDialog';
import { DeleteCompany } from 'features/api/Company';
import Notification from 'components/Common/Notification';
import ScrapeSwitch from '../../../UI/Switch';

export default function Index() {
	const { data: company, isLoading } = useGetCompanyQuery();
	const navigate = useNavigate();
	const [editMode, setEditMode] = useState(false);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [notification, setNotification] = useState({
		message: '',
		type: '',
		open: false
	});
	const [scrapeEnabled, setScrapeEnabled] = useState(false);

	const goBack = () => {
		navigate(-1);
	};

	const handleEdit = () => {
		setEditMode(true);
	};

	const handleOpenDeleteDialog = () => {
		setDeleteDialog(true);
	};

	const handleCloseDeleteDialog = () => {
		setDeleteDialog(false);
	};

	const handleScrapeSwitch = () => {
		setScrapeEnabled((value) => !value)
	};

	useEffect(() => {
		if (!isLoading) {
			setScrapeEnabled(company?.scrape_enabled);
		}
	}, [isLoading, company]);

	const handleDelete = async () => {
		setDeleting(true);
		try {
			await DeleteCompany(company?.comp_id);
			handleCloseDeleteDialog();
			navigate('/admin', {
				state: {
					showDeleteSuccessNotification: true
				}
			});
		} catch (error) {
			setNotification({
				message: `${error.response?.data ? error.response?.data : error}`,
				type: 'error',
				open: true
			});
		}
		setDeleting(false);
	};

	const handleCancel = () => {
		setEditMode(false);
	};

	const handleSave = () => {
		setEditMode(false);
	};

	return (
		<>
			<Notification
				message={notification.message}
				type={notification.type}
				open={notification.open}
				setOpen={setNotification}
			/>
			<Box className="flex flex-col h-auto w-full mt-[16px] xxl:m-48s">
				{deleteDialog && (
					<DeleteDialog
						company={company?.comp_name}
						handleCloseDeleteDialog={handleCloseDeleteDialog}
						deleting={deleting}
						handleDelete={handleDelete}
					/>
				)}
				<Box className="flex justify-between items-center">
					<Box className="flex justify-start items-center">
						<Box className="w-[40px]">
							{!editMode && (
								<WrapperButtonIcon onClick={goBack}>
									<FontAwesomeIcon
										icon={faArrowLeft}
										style={{
											cursor: 'pointer',
											color: '#9F94C4',
											fontSize: '32px'
										}}
									/>
								</WrapperButtonIcon>
							)}
						</Box>
						<Typography variant="title1" className="ml-[18px] text-accent-100">
							{!editMode ? company?.comp_name : 'Editing'}
						</Typography>
						{editMode && (
							<WrapperButtonIcon className="cursor-default">
								<FontAwesomeIcon
									icon={faPen}
									style={{
										color: '#5337B9',
										fontSize: '32px'
									}}
								/>
							</WrapperButtonIcon>
						)}
					</Box>
					{!editMode ? (
						<Box className="flex h-[36px] gap-x-4">
							<Button
								label="Delete"
								variant="delete"
								endIcon={<FontAwesomeIcon icon={faTrash} />}
								TypographyComponent={Headline6}
								onClick={handleOpenDeleteDialog}
							/>
							<Button
								label="Edit"
								variant="primary"
								endIcon={<FontAwesomeIcon icon={faPen} />}
								TypographyComponent={Headline6}
								onClick={handleEdit}
							/>
						</Box>
					) : (
						<Box className="flex h-[36px] gap-x-4">
							<Button
								label="Cancel"
								variant="secondary"
								endIcon={<FontAwesomeIcon icon={faXmarkCircle} />}
								TypographyComponent={Headline6}
								onClick={handleCancel}
							/>
							<Button
								label="Save"
								variant="primary"
								endIcon={<FontAwesomeIcon icon={faCheckCircle} />}
								TypographyComponent={Headline6}
								onClick={handleSave}
							/>
						</Box>
					)}
				</Box>
				<Box className="flex gap-x-[80px] my-[24px] ml-[60px]">
					<Box className="flex flex-col w-[160px]">
						{editMode && (
							<Typography variant="h5" className="w-full my-[16px]">
								Company Name:
							</Typography>
						)}
						<Typography variant="h5" className="w-full my-[16px]">
							Company ID:
						</Typography>
						<Typography variant="h5" className="w-full my-[16px]">
							Jobsite ID:
						</Typography>
						<Typography variant="h5" className="w-full my-[16px]">
							Website:
						</Typography>
						<Typography variant="h5" className="w-full my-[16px]">
							Jobsite Parser:
						</Typography>
						<Typography variant="h5" className="w-full my-[16px]">
							Scrape Enabled:
						</Typography>
						<Typography variant="h5" className="w-full my-[16px]">
							Jobsite URL:
						</Typography>
					</Box>
					<Box className="flex flex-col w-[480px]">
						{!editMode ? (
							<>
								<Typography variant="paragraph" className="text-accent-100 my-[16px]">
									{isLoading ? 'Loading...' : company?.comp_id}
								</Typography>
								<Typography variant="paragraph" className="my-[16px]">
									{isLoading ? 'Loading...' : company?.jobsite_id}
								</Typography>
								<Typography variant="paragraph" className="my-[16px]">
									{isLoading ? 'Loading...' : company?.website ? company?.website : 'N/A'}
								</Typography>
								<Typography variant="paragraph" className="my-[16px]">
									{isLoading
										? 'Loading...'
										: company?.jobsite_parser
										? company?.jobsite_parser
										: 'N/A'}
								</Typography>
								{isLoading ? (
									<Typography variant="paragraph" className="my-[16px]">
										Loading...
									</Typography>
								) : (
									<Box className="my-[8px]">
										<ScrapeSwitch
											scrapeEnabled={scrapeEnabled ? true : false}
											disabled={true}
											handleScrapeSwitch={handleScrapeSwitch}
										/>
									</Box>
								)}
								<Typography variant="paragraph" className="my-[16px]">
									{isLoading ? 'Loading...' : company?.jobsite_url ? company?.jobsite_url : 'N/A'}
								</Typography>
							</>
						) : (
							<>
								<InputTextAdmin value={company?.comp_name} className="h-0 mt-[8px] mb-[48px]" />
								<InputTextAdmin
									value={company?.comp_id}
									className="h-0 mt-[8px] mb-[48px]"
									inputColor="text-accent-100"
									disabled
								/>
								<InputTextAdmin
									value={company?.jobsite_id}
									className="h-0 mt-[8px] mb-[48px]"
									disabled
								/>
								<InputTextAdmin value={company?.website} className="h-0 mt-[8px] mb-[48px]" />
								<InputTextAdmin
									value={company?.jobsite_parser}
									className="h-0 mt-[8px] mb-[48px]"
								/>
								<Box className="my-[8px]">
									<ScrapeSwitch
										scrapeEnabled={scrapeEnabled ? true : false}
										disabled={false}
										handleScrapeSwitch={handleScrapeSwitch}
									/>
								</Box>
								<InputTextAdmin value={company?.jobsite_url} className="h-0 mt-[8px] mb-[48px]" />
							</>
						)}
					</Box>
				</Box>
				{/* <Box
				className={`grid grid-cols-2 gap-y-[32px] w-[${
					!editMode ? '30%' : '70%'
				}] my-[24px] ml-[60px]`}
			>
				{editMode && (
					<>
						<Typography variant="h5" className="w-full">
							Company Name:
						</Typography>
						<InputTextAdmin value={company?.comp_name} className="h-0" />
					</>
				)}

				<Typography variant="h5" className="w-full">
					Company ID:
				</Typography>
				{!editMode ? (
					<Typography variant="paragraph" className="text-accent-100">
						{company?.comp_id}
					</Typography>
				) : (
					<InputTextAdmin
						value={company?.comp_id}
						className="h-0"
						inputColor="text-accent-100"
						disabled
					/>
				)}

				<Typography variant="h5" className="w-full">
					Jobsite ID:
				</Typography>
				{!editMode ? (
					<Typography variant="paragraph">{company?.jobsite_id}</Typography>
				) : (
					<InputTextAdmin value={company?.jobsite_id} className="h-0" disabled />
				)}

				<Typography variant="h5" className="w-full">
					Website:
				</Typography>
				{!editMode ? (
					<Typography variant="paragraph">{company?.website ? company?.website : 'N/A'}</Typography>
				) : (
					<InputTextAdmin value={company?.website} className="h-0" />
				)}
			</Box> */}
			</Box>
		</>
	);
}
