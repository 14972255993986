import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBookmark } from "@fortawesome/free-solid-svg-icons";
import ProfileInitials from "../UI/ProfileInitials";
import TextField from "../UI/Input";
import { WrapperButtonIcon } from "../UI/Wrapper";
import { useState, useCallback } from "react";
import _ from "lodash";
import { SetNotes } from "../../features/api/Company";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CompanyDialog({
  company,
  search_terms,
  open,
  handleClose,
  fave,
  index,
  comp_id,
  jobsite_id,
  website,
  handleBookmarkRow,
  notes,
  setData,
  setDialog,
}) {
  const [visible, setVisible] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetNotes = useCallback(
    _.debounce((comp_id, notes) => {
      try {
        SetNotes({ comp_id, notes });
      } catch (error) {
        console.error(error);
      }
    }, 300),
    []
  );

  const handleOnChangeNotes = (event) => {
    let keyword = event.currentTarget?.value;
    setData((prevData) => {
      return prevData.map((data) => {
        if (data.comp_id === comp_id) {
          setDialog((prevData) => ({ ...prevData, notes: keyword }));
          return { ...data, notes: keyword };
        }
        return data;
      });
    });

    debouncedSetNotes(comp_id, keyword);
  };

  const wrapperStyles = {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  };

  const flexWrapperStyles = {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #CCCCCC",
    py: 2,
    mb: 1,
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle
        variant="title2"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#3E2989",
          boxShadow: "0px 2px 4px 0px #00000040",
        }}
      >
        Company Information
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            mb: 1,
          }}
        >
          <img
            src="/logo/company_logo.svg"
            alt="Company Logo"
            style={{ marginBottom: "16px" }}
          />

          <WrapperButtonIcon
            sx={{ color: fave === 1 ? "#5337B9" : "#e2e2e2" }}
            onClick={(event) => handleBookmarkRow(event, index, comp_id, fave)}
          >
            <FontAwesomeIcon size={"xl"} icon={faBookmark} />
          </WrapperButtonIcon>
        </Box>
        <Typography className="text-[18px] lg:text-[36px]">
          {company}
        </Typography>
        <Box sx={flexWrapperStyles}>
          <Box sx={wrapperStyles}>
            <Typography className=" text-grey-800 font-semibold	 text-[14px] lg:text-[18px]">
              Company ID:
            </Typography>
            <Typography className="text-[14px] lg:text-[25px]">
              {comp_id}
            </Typography>
          </Box>
          <Box sx={wrapperStyles}>
            <Typography className="text-grey-800 font-semibold text-[14px] lg:text-[18px]">
              Search Term:
            </Typography>
            <Typography className="text-[14px] lg:text-[25px]">
              {search_terms}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
        </Box>
        <Box className="block border-b border-grey-300 lg:flex lg:justify-between">
          <Box sx={wrapperStyles} className="mb-6">
            <Typography className="text-grey-800 font-semibold	text-[14px] lg:text-[18px]">
              JobSite ID:
            </Typography>
            <Typography className="text-[14px] lg:text-[25px]">
              {jobsite_id}
            </Typography>
            <Typography variant="headline4"></Typography>
            <Typography variant="paragraph"></Typography>
          </Box>
          <Box sx={wrapperStyles}>
            <Typography className="text-grey-800 font-semibold text-[14px] lg:text-[18px]">
              Website URL:
            </Typography>
            <Typography
              variant="websiteLink"
              color="accent"
              sx={{ pb: 2 }}
              className="text-[14px] lg:text-[25px]"
            >
              {website}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
        </Box>

        <Box sx={flexWrapperStyles}>
          {!visible && !notes ? (
            <IconButton disableRipple onClick={() => setVisible(true)}>
              <FontAwesomeIcon icon={faPlus} style={{ color: "#5337B9" }} />
              <Typography
                sx={{
                  fontSize: "16px",
                  px: 1,
                  fontWeight: 500,
                  color: "#444444",
                }}
              >
                Add Notes
              </Typography>
            </IconButton>
          ) : null}
          {visible || notes ? (
            <TextField
              type="text"
              onKeyDown={handleKeyDown}
              onChange={handleOnChangeNotes}
              value={notes}
              InputProps={{
                autoComplete: "off",
                startAdornment: (
                  <InputAdornment position="start" sx={{ pr: 1 }}>
                    <ProfileInitials />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{
                px: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "text.primary",
                },
              }}
            />
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </BootstrapDialog>
  );
}
