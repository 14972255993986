import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { useGetCompanyQuery } from 'features/specificCompany';
import { useGetDescriptionsQuery } from 'features/descriptions';
import { useNavigate } from 'react-router-dom';
import { handleBookmarkToggle } from 'helpers/utils/api';
import { useEffect, useState } from 'react';
import CompanyDetails from './CompanyDetails';
import Table from './Table';

export default function Index() {
	const [mongoId, setMongoId] = useState();
	const { data: company } = useGetCompanyQuery();
	const { data: descriptions } = useGetDescriptionsQuery(mongoId);
	const navigate = useNavigate();
	const [isBookmarked, setIsBookmarked] = useState();
	const [jobs, setJobs] = useState([]);

	const goBack = () => {
		navigate(-1);
	};

	const handleBookmark = (id, fave) => {
		if (handleBookmarkToggle(id, fave)) {
			setIsBookmarked(fave === 1 ? 0 : 1);
		}
	};

	useEffect(() => {
		if (company) {
			setIsBookmarked(company?.fave);
			setMongoId(company?.mongo_id);
		}
	}, [company]);

	useEffect(() => {
		if (descriptions) {
			setJobs(descriptions.jobs);
		}
	}, [descriptions]);

	return (
		<Box className="flex flex-col h-auto w-full mx-[72px] my-[48px] xxl:m-48s">
			<Box className="flex justify-start items-center">
				<FontAwesomeIcon
					icon={faArrowLeft}
					className="pl-2"
					style={{
						cursor: 'pointer',
						color: '#5337B9',
						fontSize: '32px'
					}}
					onClick={goBack}
				/>
				<Typography variant="title1" className="ml-[24px]">
					{company?.comp_name}
				</Typography>
				<FontAwesomeIcon
					icon={faBookmark}
					className="pl-2"
					style={{
						cursor: 'pointer',
						color: isBookmarked === 1 ? '#5337B9' : '#e2e2e2',
						fontSize: '32px',
						marginLeft: '8px'
					}}
					onClick={() => handleBookmark(company.comp_id, isBookmarked)}
				/>
			</Box>
			<CompanyDetails company={company} />
			<Table results={jobs} />
		</Box>
	);
}
