import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';
import { InputSearch } from '../UI/Input';

export default function Header({ results, setProcessData, setSelectedSearchResult }) {
	const [open, setOpen] = useState(false);
	const [searchResults, setSearchResults] = useState();
	const navigate = useNavigate();

	// const handleOnSearch = (event) => {
	//   const keyword = event?.currentTarget?.value;
	//   if (keyword) {
	//     const filtered = _.filter(results, (result) =>
	//       _.includes(result.company, keyword)
	//     );
	//     setProcessData(filtered);
	//   }
	//   if (keyword === "") return setProcessData(null);
	// };

	const handleInput = (value) => {
		setOpen(value ? true : false);
		setSearchResults(() => {
			return (
				value &&
				results
					?.filter((item) => {
						return item && item.comp_name && item.comp_name.toLowerCase().includes(value);
					})
					.slice(0, 5)
			); // Limiting to at most 5 results
		});
	};

	const handleClick = (item) => {
		setSelectedSearchResult(item);
	};

	const handleIgnoreListClick = () => {
		navigate('/favorites/ignore-list');
	};

	return (
		<Box className="block lg:flex justify-between items-center w-full pb-5">
			<Typography className="text-[25px] text-purple-300 font-medium">
				Favorite Companies
			</Typography>
			<Box className="flex items-center gap-[24px]">
				<Typography
					variant="regularLink"
					sx={{ color: '#9F94C4', cursor: 'pointer' }}
					onClick={handleIgnoreListClick}
				>
					My Ignore List
				</Typography>
				<Box className="min-w-[260px]">
					<InputSearch
						placeholder="Search"
						// onChange={(event) => handleOnSearch(event)}
						sx={{
							'& .MuiInputBase-root': {
								height: '48px',
								fontSize: '18px',
								fontWeight: 400
							}
						}}
						onChange={(event) => {
							handleInput(event.target.value);
						}}
					/>
					{open && (
						<Paper className="z-10 absolute flex flex-col lg:min-w-[300px] md:min-w-[90%] sm:min-w-[88%]">
							{searchResults.map((item, index) => (
								<Typography
									key={index}
									onClick={() => handleClick(item)}
									variant="paragraph"
									className="cursor-pointer m-[10px] px-[10px] py-[4px]"
								>
									{item.comp_name}
								</Typography>
							))}
						</Paper>
					)}
				</Box>
			</Box>
		</Box>
	);
}
