import { Paper, Box, Typography } from "@mui/material";
import { useState } from "react";
import { WrapperButtonIcon } from "./Wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarked } from "@fortawesome/free-regular-svg-icons";

export default function Card({ children, onClickCardHandler }) {
  return (
    <Paper
      className="border-2 rounded-card border-grey-300 px-6 py-3 my-4 shadow-none hover:cursor"
      onClick={onClickCardHandler}
    >
      {children}
    </Paper>
  );
}

export const AdminCard = ({ children, onClickCardHandler }) => {
  return (
    <Paper
      className="border-2 rounded-card border-grey-300 px-6 py-3 my-4 shadow-none hover:cursor"
      onClick={onClickCardHandler}
    >
      {children}
    </Paper>
  );
};

export const ResultCard = ({
  company,
  search_date,
  initial_date,
  search_terms,
  job_url,
  handleBookmarkRow,
  handleCompanyDialog,
  fave,
  index,
  comp_id,
}) => {
  const [expanded, setExpanded] = useState(false);
  const onClickCardHandler = () => setExpanded((prevValue) => !prevValue);

  return (
    <Paper
      className="border-2 rounded-card border-grey-300 px-6 py-4 my-4 shadow-none hover:cursor"
      onClick={onClickCardHandler}
    >
      {!expanded && (
        <Box className="flex flex-col w-full">
          <Box className="flex justify-between">
            <Typography variant="card">{search_terms}</Typography>
            <Typography
              variant="card"
              sx={{
                fontStyle: "italic",
                fontWeight: 600,
              }}
            >
              First Date
            </Typography>
          </Box>
          <Box className="flex justify-between">
            <Typography variant="card" sx={{ fontWeight: 350 }}>
              {company}
            </Typography>
            <Typography variant="card" sx={{ fontWeight: 350 }}>
              {initial_date}
            </Typography>
          </Box>
        </Box>
      )}
      {expanded && (
        <>
          <Box className="flex flex-col">
            <Box className="flex items-center justify-between">
              <Typography variant="card" sx={{ fontWeight: 600 }}>
                Search Term
              </Typography>
            </Box>

            <Typography
              variant="card"
              sx={{
                fontWeight: 350,
                mb: "16px",
              }}
            >
              {search_terms}
            </Typography>
            <Typography variant="card" sx={{ fontWeight: 600 }}>
              Company
            </Typography>
            <Box sx={{ mb: "16px" }}>
              <WrapperButtonIcon
                sx={{
                  padding: 0,
                  pr: 1,
                  color: fave === 1 ? "#5337B9" : "#9F94C4",
                }}
                onClick={(event) => {
                  handleBookmarkRow(event, index, comp_id, fave);
                }}
              >
                <FontAwesomeIcon
                  size={"sm"}
                  icon={fave === 1 ? faBookmark : faBookmarked}
                />
              </WrapperButtonIcon>
              <Typography
                variant="card"
                onClick={(event) => handleCompanyDialog(event)}
                sx={{
                  fontWeight: 350,
                  mb: "16px",
                }}
              >
                {company}
              </Typography>
            </Box>

            <Typography variant="card" sx={{ fontWeight: 600 }}>
              Job Request Link
            </Typography>
            <Typography
              variant="card"
              sx={{
                fontWeight: 350,
                mb: "16px",
              }}
            >
              {job_url.map((url, index) => {
                return (
                  <Box className="inline-block w-[85px]" key={index}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-accent-100"
                    >
                      {`Job Req ${++index}`}
                    </a>
                  </Box>
                );
              })}
            </Typography>
            <Typography variant="card" sx={{ fontWeight: 600 }}>
              Latest Date
            </Typography>
            <Typography
              variant="card"
              sx={{
                fontWeight: 350,
                mb: "16px",
              }}
            >
              {search_date}
            </Typography>
            <Typography variant="card" sx={{ fontWeight: 600 }}>
              First Date
            </Typography>
            <Typography
              variant="card"
              sx={{
                fontWeight: 350,
              }}
            >
              {initial_date}
            </Typography>
          </Box>
        </>
      )}
    </Paper>
  );
};

export const FavoriteCard = ({
  company = "Zendesk",
  numberOfJobs = "123",
  analytics = "Analytics",
  analytics2 = "Analytics",
  onClickHandler,
}) => {
  const [expanded, setExpanded] = useState(false);
  const onClickCardHandler = () => setExpanded((prevValue) => !prevValue);

  return (
    <Paper
      className="border-2 rounded-card border-grey-300 px-6 py-4 my-4 shadow-none"
      onClick={onClickCardHandler}
    >
      {!expanded && (
        <Box className="flex justify-between">
          <Typography variant="card">{company}</Typography>
          <Typography
            variant="card"
            sx={{ fontWeight: 350 }}
          >{`${numberOfJobs} Jobs`}</Typography>
        </Box>
      )}
      {expanded && (
        <Box className="flex flex-col">
          <Typography variant="card" sx={{ fontWeight: 600 }}>
            Company
          </Typography>
          <Typography
            variant="card"
            sx={{
              fontWeight: 350,
              mb: "16px",
            }}
          >
            {company}
          </Typography>
          <Typography variant="card" sx={{ fontWeight: 600 }}>
            No. of Jobs
          </Typography>
          <Typography
            variant="card"
            sx={{
              fontWeight: 350,
              mb: "16px",
            }}
          >
            {numberOfJobs}
          </Typography>
          <Typography variant="card" sx={{ fontWeight: 600 }}>
            Analytics
          </Typography>
          <Typography
            variant="card"
            sx={{
              fontWeight: 350,
              mb: "16px",
            }}
          >
            {analytics}
          </Typography>
          <Typography variant="card" sx={{ fontWeight: 600 }}>
            Analytics
          </Typography>
          <Typography
            variant="card"
            sx={{
              fontWeight: 350,
            }}
          >
            {analytics2}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};
