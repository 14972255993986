import { Box, Paper, Typography } from '@mui/material';
import { InputSearch } from '../UI/Input';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCompaniesQuery } from 'features/companies';

export default function Index() {
	const { data } = useGetCompaniesQuery();
	const [open, setOpen] = useState(false);
	const [results, setResults] = useState();
	const [initializing, setInitializing] = useState(true);
	const navigate = useNavigate();

	const handleInput = (value) => {
		setOpen(value ? true : false);
		setResults(() => {
			return data
				?.filter((item) => {
					return item && item.comp_name && item.comp_name.toLowerCase().includes(value);
				})
				.slice(0, 5); // Limiting to at most 5 results
		});
	};

	const handleClick = (item) => {
		navigate(`/companies/${item.comp_id}`);
	};

	useEffect(() => {
		if (data && data.length > 0) {
			setInitializing(false);
		}
	}, [data, setInitializing]);

	return (
		<Box className="flex justify-center items-center h-auto w-full mx-10 my-20 xxl:m-48">
			<Box className="flex flex-col justify-center items-center w-full lg:w-[70%] 2xl:w-[60%]">
				<Typography className="text-3xl mb-10 text-purple-300 font-medium">Companies</Typography>
				<InputSearch
					placeholder={initializing ? 'Loading...' : 'Search'}
					sx={{
						'& .MuiInputBase-root': {
							height: '48px',
							fontSize: '18px',
							fontWeight: 400
						}
					}}
					onChange={(event) => {
						handleInput(event.target.value);
					}}
					disabled={initializing}
				/>
				{open && (
					<Paper className="flex flex-col w-full">
						{results?.map((item, index) => (
							<Typography
								key={index}
								onClick={() => handleClick(item)}
								variant="paragraph"
								className="cursor-pointer m-[10px] px-[10px] py-[4px]"
							>
								{item.comp_name}
							</Typography>
						))}
					</Paper>
				)}
			</Box>
		</Box>
	);
}
