import React from 'react';
import { Box } from '@mui/material';
import Button from '../../UI/Button';
import { faRotate, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Headline6 } from '../../UI/Typography';

export default function Header({ handleRefresh, handleOpenAddDialog, refreshing }) {
	return (
		<Box className="flex flex-col mb-[80px] items-end w-full">
			<Box className="flex items-center justify-between">
				<Box className="flex gap-4 w-auto">
					<Button
						variant="secondary"
						label={refreshing ? 'Refreshing...' : 'Refresh'}
						TypographyComponent={Headline6}
						startIcon={<FontAwesomeIcon icon={faRotate} />}
						onClick={handleRefresh}
						disabled={refreshing}
					/>
					<Button
						variant="primary"
						label="Add"
						TypographyComponent={Headline6}
						startIcon={<FontAwesomeIcon icon={faPlus} />}
						onClick={handleOpenAddDialog}
					/>
				</Box>
			</Box>
		</Box>
	);
}
