import { Typography, Box, IconButton } from "@mui/material";
import Button from "../UI/Button";

export default function Header({ form, backToLogin, onClick }) {
  const landingPageStyle =
    "flex items-center justify-center xs:h-[48px] md:h-[80px] ";
  // const formPageStyle =
  //   "bg-white-100 flex items-center justify-center h-[80px] w-full fixed border-b border-grey-300";

  return (
    <>
      <Box
        className={`${landingPageStyle} ${
          form ? "border-b border-grey-300 mb-[40px]" : ""
        }`}
      >
        <Box className="flex items-center justify-between h-full w-full p-4 md:w-4/5">
          <IconButton
            disableRipple
            className="flex h-full items-center hover:bg-transparent"
            onClick={() => backToLogin(!true)}
          >
            <img
              src="/logo/logo-dark.svg"
              alt="OnlineLeads Logo"
              height="30" // Set your desired height here
              width="50" // Set your desired width here
            />
            <Typography color="primary" variant="h5">
              OnlineLeads
            </Typography>
          </IconButton>
          <Box className="h-[50px] w-[100px] lg:w-[120px]">
            <Button variant="primary" label="Login" onClick={onClick} />
          </Box>
        </Box>
      </Box>

      {form && <Box sx={{ display: "block" }}></Box>}
    </>
  );
}
