import { useState } from "react";
import { Box, useTheme, Typography, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { jwtParser } from "../../helpers/utils/parser";
import { toggleNav } from "../../features/navigation";
import { useDispatch } from "react-redux";
import ProfileInitials from "../UI/ProfileInitials";
import Cookies from 'js-cookie';

export default function Settings() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { given_name, family_name, emails } = jwtParser();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);
  };

  const handleMenuOnClick = (event) => {
    //Add callback for dropdown
  };

  const handleHelpOnClick = () => {
    navigate("/help");
    setAnchorElUser(false);
  };

  const handleLogout = () => {
    dispatch(toggleNav());
    localStorage.removeItem("access_token");
    Cookies.remove('persistHideTips');
    navigate("/");
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <ProfileInitials handleOnClick={handleOpenUserMenu} />
      <Menu
        sx={{ mt: "65px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "125px",
          }}
        >
          <Typography
            color="primary"
            sx={{
              color: "#423864",
              fontSize: "14px",
              alignSelf: "start",
              fontWeight: 600,
              m: 2,
            }}
          >
            ACCOUNT
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              ml: 2,
            }}
          >
            <ProfileInitials handleOnClick={handleMenuOnClick} />
            <Box>
              <Typography sx={{ fontSize: "15px", fontWeight: 400, ml: 1 }}>
                {given_name && family_name
                  ? `${given_name} ${family_name}`
                  : `--`}
              </Typography>
              <Typography sx={{ fontSize: "15px", fontWeight: 400, ml: 1 }}>
                {emails ? emails[0] : "user@domain.com"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <MenuItem
          sx={{
            width: "300px",
            height: "62.5px",
            borderTop: "1px solid #CCCCCC",
          }}
          onClick={handleMenuOnClick}
          name="ChangePassword"
        >
          <Typography sx={{ fontSize: "17px", fontWeight: 400 }}>
            Change Password
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{
            width: "300px",
            height: "62.5px",
            borderBottom: "1px solid #CCCCCC",
          }}
          onClick={handleHelpOnClick}
          name="ChangePassword"
        >
          <Typography sx={{ fontSize: "17px", fontWeight: 400 }}>
            Help
          </Typography>
        </MenuItem>
        {/* <MenuItem
          sx={{
            width: "300px",
            height: "62.5px",
            borderBottom: "1px solid #CCCCCC",
          }}
          onClick={handleMenuOnClick}
          name="ManageSubscription"
        >
          <Typography sx={{ fontSize: "17px", fontWeight: 400 }}>
            Manage Subscription
          </Typography>
        </MenuItem> */}

        <MenuItem
          name="Logout"
          sx={{
            width: "300px",
            height: "50px",
            "& svg": {
              fontSize: "25px",
              color: theme.palette.primary.main,
            },
          }}
          onClick={handleLogout}
        >
          <FontAwesomeIcon icon={faRightFromBracket} />
          <Typography sx={{ fontSize: "17px", fontWeight: 400, ml: 1.25 }}>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
