import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { Paragraph } from "../UI/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SideNav() {
  const AdminPages = [
    { label: "Companies", destination: "/admin", icon: faDatabase },
  ];
  const navigate = useNavigate();
  const handleNavigatePage = (destination) => navigate(destination);

  return (
    <Box className="h-screen w-[360px] bg-purple-100">
      <Box
        className="flex h-[70px] justify-start items-center pl-6 py-4 hover:cursor-pointer"
        onClick={() => handleNavigatePage("/results")}
      >
        <img src="/logo/logo-white.svg" alt="logo" width={56} height={36} />
        <Typography className="text-white-100 text-[28px] font-bold">
          OnlineLeads
        </Typography>
      </Box>
      <Box className="flex w-full flex-col justify-center items-center mt-12">
        {AdminPages.map(({ label, destination, icon }, index) => {
          return (
            <Button
              className="w-4/5 h-[56px] hover:bg-purple-1000 flex justify-start pl-6 shadow-none py-4 mb-6"
              onClick={() => handleNavigatePage(destination)}
              label={label}
              key={index}
              startIcon={
                <FontAwesomeIcon
                  icon={icon}
                  className="p-0 m-0 pr-4 text-white-100"
                />
              }
              TypographyComponent={Paragraph}
              typographyProps={{
                className: "text-white-100",
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
}
