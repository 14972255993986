import FAQ from '../UI/FAQ';
import { Box, Typography } from '@mui/material';

export const GettingStarted = () => {
	return (
		<Box className="flex flex-col">
			<Typography variant="h3" className="text-purple-100 mb-[40px]">
				Getting Started
			</Typography>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Creating an Account
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Go to{' '}
						<span className="cursor-pointer text-purple-100">Onlineleads.io</span> and click the "Get
						Started" button.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: Fill out the form to request an invite code/link.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 3</b>: Wait for an email from our team with the invite code/link.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 4</b>: Follow the instructions in the email to complete your
						registration.
					</Typography>
				</Box>
			</Box>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Logging In
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Go to{' '}
						<span className="cursor-pointer text-purple-100">Onlineleads.io</span> and click the
						"Login" button at the top right corner.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: Enter your email and password.
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export const ManagingAccount = () => {
	return (
		<Box className="flex flex-col">
			<Typography variant="h3" className="text-purple-100 mb-[40px]">
				Managing Your Account
			</Typography>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Changing Password
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Click your profile icon at the top right corner of the navigation
						bar.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: Select "Change Password."
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 3</b>: Enter your current password and the new password.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 4</b>: Save the changes to update your password.
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export const UsingSearchTerms = () => {
	return (
		<Box className="flex flex-col">
			<Typography variant="h3" className="text-purple-100 mb-[40px]">
				Using Search Terms
			</Typography>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Adding Search Terms
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Go to the "Search Terms" page.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: Enter your main search term (1-5 terms).
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 3</b>: Optionally, add 1-2 extra terms related to your main search term
						to filter results.
					</Typography>
				</Box>
			</Box>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Editing Search Terms
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Go to the "Search Terms" page.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: Click on the search term field(s) that you want to edit and type
						the change.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 3</b>: Click the "Save" button to apply the changes or "Cancel" to
						discard them.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 4</b>: You also have the option to delete a row of search terms by
						clicking the "Delete" button on the left side of the search term field.
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export const ViewingResults = () => {
	return (
		<Box className="flex flex-col">
			<Typography variant="h3" className="text-purple-100 mb-[40px]">
				Viewing Results
			</Typography>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Accessing the Results Page
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Navigate to the "Results" page.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: View the list of companies and job links matching your search
						terms.
					</Typography>
				</Box>
			</Box>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Filtering Results
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Click the "Search Terms" button at the top of the table.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: Select the desired filters.
					</Typography>
				</Box>
			</Box>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Sorting Results
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Click the column titles with the sort button to sort the results
						table based on your preference (e.g., by search terms, company name, latest date, or
						first date).
					</Typography>
				</Box>
			</Box>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Bookmarking Favorites
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Click the favorites button next to the company you wish to
						bookmark.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: Click on the company name to see more details and add notes.
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export const ManagingFavorites = () => {
	return (
		<Box className="flex flex-col">
			<Typography variant="h3" className="text-purple-100 mb-[40px]">
				Managing Favorites
			</Typography>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Viewing Favorite Companies
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Go to the "Favorites" page.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: See all the companies you’ve bookmarked.
					</Typography>
				</Box>
			</Box>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Removing Favorites
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Go to the "Favorites" page.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: Click the favorites button next to the company you no longer want
						to bookmark to remove it from your favorites.
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export const SearchingForCompanies = () => {
	return (
		<Box className="flex flex-col">
			<Typography variant="h3" className="text-purple-100 mb-[40px]">
				Searching for Companies
			</Typography>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Using the Search Bar
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: Go to the "Companies" page.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: Enter the company name in the search bar.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 3</b>: Click on the company name to be redirected to its page.
					</Typography>
				</Box>
			</Box>
			<Box className="mb-[40px]">
				<Typography variant="h4" className="text-purple-100 mb-[24px]">
					&gt; Viewing Company Details
				</Typography>
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>&bull; Step 1</b>: On the company’s page, see detailed information about the company.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Step 2</b>: View the list of available jobs under that company.
					</Typography>
					<Typography variant="paragraph">
						<b>&bull; Favorites and Ignore</b>: Beside the company name, there is a favorites button
						to bookmark the company and an ignore company button to exclude it from your search
						results.
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export const FAQs = () => {
	const questions = [
		'Q1: What is OnlineLeads?',
		'Q2: How do I create an account?',
		'Q3: How do I add search terms?',
		'Q4: How do I bookmark a company?',
		'Q5: How do I remove a company from my favorites'
	];

	return (
		<Box className="flex flex-col">
			<Typography variant="h3" className="text-purple-100 mb-[32px]">
				FAQs
			</Typography>
			<Box className="flex flex-col gap-y-[32px] mb-[40px]">
				{questions.map((question, index) => (
					<FAQ key={index} question={question} />
				))}
			</Box>
		</Box>
	);
};

export const ContactUs = () => {
	return (
		<Box className="flex flex-col">
			<Typography variant="h3" className="text-purple-100 mb-[40px]">
				Contact Us
			</Typography>
			<Box className="mb-[40px]">
				<Typography variant="paragraph" className="mb-[24px]">
					If you have any questions or need further assistance, please do not hesitate to contact
					our support team.
				</Typography>
				{/* TODO: Exact contact info */}
				<Box className="flex flex-col gap-y-[16px]">
					<Typography variant="paragraph">
						<b>Email</b>: support@[website name].com
					</Typography>
					<Typography variant="paragraph">
						<b>Phone</b>: 1-800-123-4567
					</Typography>
					<Typography variant="paragraph">
						<b>Contact Form</b>: [Link to Contact Form]
					</Typography>
					<Typography variant="paragraph">
						<b>
							Thank you for choosing <span className="text-purple-100">OnlineLeads</span>! We’re here
							to help you succeed.
						</b>
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
