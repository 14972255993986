import React, { useState, useEffect, useCallback } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
	TablePagination,
	useTheme
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { WrapperButtonIcon } from '../UI/Wrapper';
import CompanyDialog from '../Common/CompanyDialog';
import { handleBookmarkToggle } from '../../helpers/utils/api';
import { ResponsiveTableHeights } from '../../tailwind.custom';
import _ from 'lodash';

const DataTableFavorites = ({
	tableData,
	columns,
	selectedSearchResult,
	setSelectedSearchResult
}) => {
	const theme = useTheme();
	const [bookmarkedRows, setBookmarkedRows] = useState([...Array(tableData.length).keys()]);
	const [companyDialog, setOpenDialog] = useState({
		open: false,
		name: '',
		fave: 1
	});
	const [sortedData, setSortedData] = useState(null);
	const [sortDirection, setSortDirection] = useState(true);
	const [transformedData, setTransformedData] = useState();
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const rowsPerPageOptions = [10, 20, 30];

	const handlePageChange = (event, page) => {
		setCurrentPage(page);
		const startIndex = page * rowsPerPage;
		const endIndex = Math.min(startIndex + rowsPerPage, transformedData.length);
		setSortedData(transformedData.slice(startIndex, endIndex));
	};
	const handlerowsPerPageChange = (event) => {
		const newrowsPerPage = parseInt(event.target.value, 10);
		setRowsPerPage(newrowsPerPage);
		setCurrentPage(0);
		const startIndex = 0;
		const endIndex = Math.min(startIndex + newrowsPerPage, transformedData.length);
		setSortedData(transformedData.slice(startIndex, endIndex));
	};

	const onClickHandlerSort = (column) => {
		const newSortedData = _.orderBy(transformedData, [column], [sortDirection ? 'asc' : 'desc']);
		setSortedData(newSortedData);
		setSortDirection((prevValue) => !prevValue);
	};

	// Function to handle bookmarking rows
	const handleBookmarkRow = (row, rowIndex) => {
		setBookmarkedRows((prevBookmarkedRows) => {
			if (prevBookmarkedRows.includes(rowIndex)) {
				// fave value is hard-coded because it's not included in the favorites response
				handleBookmarkToggle(row.comp_id, 1);
				return prevBookmarkedRows.filter((index) => rowIndex !== index);
			} else {
				handleBookmarkToggle(row.comp_id, 0);
				return [...prevBookmarkedRows, rowIndex];
			}
		});
	};

	const handleOpenDialog = useCallback(
		(props) => {
			setOpenDialog((data) => ({ ...data, ...props, open: true }));
		},
		[setOpenDialog]
	);

	const handleCloseDialog = () => setOpenDialog((data) => ({ ...data, open: false }));

	useEffect(() => {
		setTransformedData(tableData);
		setBookmarkedRows([...Array(tableData.length).keys()]);
	}, [tableData]);

	useEffect(() => {
		if (selectedSearchResult) {
			handleOpenDialog(selectedSearchResult);
			setSelectedSearchResult(null);
		}
	}, [selectedSearchResult, setSelectedSearchResult, handleOpenDialog]);

	useEffect(() => {
		if (transformedData) {
			setSortedData(transformedData.slice(0, rowsPerPage));
		}
	}, [transformedData, rowsPerPage]);

	const renderRows = (renderData) => {
		return (
			renderData &&
			renderData.map((row, index) => (
				<TableRow
					key={index}
					sx={{
						'&:hover': {
							background: '#F2F2F2'
						}
					}}
				>
					<TableCell
						className="text-[12px] py-2 px-4 w-[48px]"
						sx={{
							color: '#615D70',
							fontWeight: 500,
							fontSize: '18px'
						}}
					>
						<FontAwesomeIcon
							size={'lg'}
							icon={faBookmark}
							onClick={() => handleBookmarkRow(row, index)}
							style={{
								cursor: 'pointer',
								color: bookmarkedRows?.includes(index) ? '#5337B9' : '#e2e2e2'
							}}
						/>
					</TableCell>
					{columns.map((column, columnIndex) => (
						<TableCell
							key={columnIndex}
							sx={{
								color: '#615D70',
								fontWeight: 500,
								fontSize: '12px',
								padding: 0
							}}
						>
							{(() => {
								switch (column.key) {
									case 'company':
										return (
											<>
												<Box
													className="inline-block cursor-pointer hover:text-accent-100 hover:font-bold"
													key={index}
													onClick={() => handleOpenDialog({ ...row, index })}
												>
													{row[column.key]}
												</Box>
											</>
										);
									default:
										return (
											<Box className="inline-block" key={index}>
												{row[column.key]}
											</Box>
										);
								}
							})()}
						</TableCell>
					))}
				</TableRow>
			))
		);
	};

	return (
		<Box className="">
			<CompanyDialog
				open={companyDialog.open}
				handleClose={handleCloseDialog}
				handleBookmarkRow={handleBookmarkRow}
				setData={setTransformedData}
				setDialog={setOpenDialog}
				{...companyDialog}
			/>
			<Paper sx={{ width: '100%' }}>
				<TableContainer className={`${ResponsiveTableHeights} + border border-grey-300`}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell className="p-0 bg-purple-800 w-[48px]"></TableCell>
								{columns.map((column, index) => (
									<TableCell
										key={index}
										className="text-[14px] font-semibold p-0 py-3 bg-purple-800"
										// Add minWidth and maxWidth styling
										style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
									>
										<Box className="flex justify-start items-center">
											{column.title}
											<WrapperButtonIcon
												onClickHandler={() => onClickHandlerSort(column.key)}
												color="accent"
												className="p-0"
											>
												{/* Remove sort icon in Notes column */}
												{column.title !== 'Notes' && (
													<FontAwesomeIcon icon={faSort} className="pl-2" />
												)}
											</WrapperButtonIcon>
										</Box>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{sortedData ? renderRows(sortedData) : renderRows(transformedData)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'right',
					alignItems: 'center',
					margin: '5px 0px 5px 5px',
					color: 'red'
				}}
			>
				<TablePagination
					sx={{
						'& .MuiTablePagination-select': {
							minHeight: 'auto',
							fontSize: '15px'
						},
						'& .MuiTablePagination-selectLabel': {
							color: theme.palette.secondary.light
						},
						'& .MuiTablePagination-selectIcon': {
							color: theme.palette.accent.main
						},
						'& .MuiTablePagination-toolbar': {
							color: theme.palette.secondary.light
						},
						'& .MuiTablePagination-actions': {
							color: theme.palette.accent.main
						}
					}}
					component="div"
					count={transformedData ? transformedData.length : 0}
					page={currentPage}
					onPageChange={handlePageChange}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handlerowsPerPageChange}
					rowsPerPageOptions={rowsPerPageOptions}
					showFirstButton
					showLastButton
				/>
			</Box>
		</Box>
	);
};

export default DataTableFavorites;
