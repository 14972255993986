import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiBaseUrl } from '../ENDPOINT';
import { prepareHeaders } from '../Header';
import _ from 'lodash';

export const resultsApi = createApi({
  reducerPath: 'resultsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getResults: builder.query({
      query: () => ({
        url: '/results',
        method: 'GET',
      }),
      transformResponse: async (response) => {
        //console.log(response.data);
        try {
          const combinedResponse = [].concat(
            ...Object.keys(response).map((key) => {
              return response[key].map((obj) => ({
                ...obj,
                search_terms: key,
              }));
            })
          );
          let finalResponse = combinedResponse.map((data) => {
            return {
              ...data,
              job_url: JSON.parse(data.job_url.replace(/'/g, '"')),
              company: data.comp_name,
            };
          });

          const sortedResponse = _.orderBy(
            finalResponse,
            ['initial_date'],
            ['desc']
          );

          //console.log({ sortedResponse });
          return sortedResponse;
        } catch (error) {
          console.error(error);
        }
      },
      onError: (error, query) => {
        //console.log('An error occurred while fetching results:', error);
      },
    }),
  }),
});

export const { useGetResultsQuery } = resultsApi;
