import SideNav from "./SideNav";
import Header from "./Header";
import { Box } from "@mui/material";
import Search from "./Search";

export default function Admin() {
  return (
    <Box className="flex w-full h-full">
      <SideNav />
      <Box className="flex flex-col w-full">
        <Header />
        <Box className="h-full px-10 py-4">
          <Search />
        </Box>
      </Box>
    </Box>
  );
}
