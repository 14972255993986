import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import Button from '../../UI/Button';
import { WrapperButtonIcon } from '../../UI/Wrapper';

export default function SuccessDialog({ handleCloseSuccessDialog, handleViewCompany }) {
	return (
		<Box className="fixed z-10 inset-0 overflow-y-auto">
			<Box className="flex items-center justify-center min-h-screen">
				<Box
					className="fixed z-10 inset-0 bg-black-100 bg-opacity-50 transition-opacity"
					aria-hidden="true"
				></Box>
				<Box className="z-30 bg-white-100 relative p-8 shadow-xl w-[55%]">
					<Box className="flex justify-end items-center mb-8">
						<WrapperButtonIcon className="cursor-default">
							<FontAwesomeIcon
								icon={faXmark}
								style={{
									cursor: 'pointer',
									color: '#71717A',
									fontSize: '32px'
								}}
								onClick={handleCloseSuccessDialog}
							/>
						</WrapperButtonIcon>
					</Box>
					<Box className="flex flex-col justify-center items-center gap-y-[72px]">
						<Box className="flex flex-col gap-y-[20px] justify-center items-center">
							<WrapperButtonIcon className="cursor-default">
								<FontAwesomeIcon
									icon={faCheckCircle}
									style={{
										color: '#388E3C',
										fontSize: '120px'
									}}
								/>
							</WrapperButtonIcon>
							<Typography variant="title1">New Company Added!</Typography>
							<Typography variant="paragraph" className="text-center">
								The new company has been successfully added to the database.
								<br />
								You may click the button below to view company details.
							</Typography>
						</Box>
						<Box className="flex">
							<Button
								className="text-white-100 text-[16px] xl:text-[18px]"
								label="View Company"
								variant="primary"
								onClick={handleViewCompany}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
