import React from 'react';
import Results from './Results';
import { Box, Typography } from '@mui/material';
import { useGetResultsQuery } from '../../features/results';
import { useGetSearchTermsQuery } from '../../features/searchTerms';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../Common/Loading';
import { ButtonFilter } from '../UI/Button';
import { useState, useEffect } from 'react';
import MobileView from './MobileView';
import Cookies from 'js-cookie';
import TipsDialog from './TipsDialog';

export default function ResultsComponent() {
	const { data: results, isLoading, isError, refetch } = useGetResultsQuery();
	const { data: currentSearchTerms } = useGetSearchTermsQuery();
	const [filterButton, setFilterButton] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);
	const navigate = useNavigate();
	const location = useLocation();
	const [tipsDialog, setTipsDialog] = useState(null);
	const accessToken = localStorage?.getItem('access_token');

	const handleCloseTipsDialog = () => {
		setTipsDialog(false);
		Cookies.set('persistHideTips', true, { expires: 365 });
	};

	useEffect(() => {
		refetch();

		const showTips = Cookies.get('showTips');
		const persistHideTips = Cookies.get('persistHideTips');

		if (persistHideTips === 'true') {
			setTipsDialog(false);
		} else if (showTips === 'false') {
			setTipsDialog(false);
		} else if (showTips === 'true' && persistHideTips === undefined && accessToken) {
			setTipsDialog(true);
		} else {
			setTipsDialog(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (currentSearchTerms) {
			const updatedFilterButtonState = currentSearchTerms.map((data) => ({
				name: data.combined_search_term,
				selected: true
			}));
			setFilterButton(updatedFilterButtonState);

			// If search terms are empty, redirect to Search Terms page
			if (currentSearchTerms.length === 0) {
				navigate('/search-terms', { state: { showErrorNotificationFromResults: true } });
			}
		}
	}, [currentSearchTerms, navigate]);

	const onClickFilterHandler = (event) => {
		const { name } = event.currentTarget;
		setFilterButton((prevValue) =>
			prevValue.map((data) => {
				return data.name === name ? { ...data, selected: !data.selected } : data;
			})
		);
	};

	useEffect(() => {
		if (results && filterButton) {
			setFilteredResults(
				results.filter((data, index) => {
					const filterItem = filterButton.find((filter) => filter.name === data.search_terms);
					return filterItem && filterItem.selected;
				})
			);
		}

		if (
			location.state &&
			(location.state.searchTermFieldSelected || location.state.searchTermFieldSelected === 0)
		) {
			const searchTermFieldSelected = location.state.searchTermFieldSelected;
			setFilterButton((prevValue) => {
				const updatedFilterButton = prevValue?.map((item, index) => {
					index === searchTermFieldSelected ? (item.selected = true) : (item.selected = false);
					return {
						...item
					};
				});

				return updatedFilterButton;
			});
			location.state.searchTermFieldSelected = null;
		}
	}, [results, filterButton, location.state]);

	return (
		<Box className="w-full mx-[40px] my-[32px] xxl:m-[48px]">
			{tipsDialog && <TipsDialog handleCloseTipsDialog={handleCloseTipsDialog} />}
			{!isError && !isLoading && !tipsDialog && (
				<Box className="hidden lg:flex xl:flex 2xl:flex items-center justify-start w-full">
					<Box className="w-full">
						<Typography className="text-[25px] text-purple-300 font-medium">Results</Typography>
						<Box className="flex items-center mb-4 gap-x-6">
							<Typography variant="title3"> Search Terms: </Typography>
							{filterButton &&
								filterButton.map(({ name, selected }, index) => {
									return (
										<ButtonFilter
											onClick={onClickFilterHandler}
											name={name}
											label={name}
											active={selected}
											key={index}
										/>
									);
								})}
						</Box>

						<Results results={filteredResults} isLoading={isLoading} isError={isError} />
					</Box>
				</Box>
			)}
			{isLoading && !tipsDialog && <Loading />}
			<Box className="flex lg:hidden xl:hidden 2xl:hidden flex-col w-full">
				{isLoading ? <Loading /> : <MobileView results={results} />}
			</Box>
		</Box>
	);
}
