import React, { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
	faCircleXmark,
	faCircleCheck,
	faTriangleExclamation,
	faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notification({ open, setOpen, message, type }) {
	const handleClose = (_event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen((data) => ({ ...data, open: false }));
	};

	// Define custom icon mapping
	const customIconMapping = {
		success: <FontAwesomeIcon icon={faCircleCheck} className="text-green-100" />,
		error: <FontAwesomeIcon icon={faCircleXmark} className="text-red-100" />,
		warning: <FontAwesomeIcon icon={faTriangleExclamation} className="text-yellow-100" />,
		info: <FontAwesomeIcon icon={faCircleInfo} style={{ color: '#0074B7' }} />
	};

	return message && type ? (
		<Stack spacing={2} sx={{ width: '100%' }}>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				open={open}
				autoHideDuration={3500}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					severity={type}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: '#FFFFFF',
						color: '#020104',
						width: '75%',
						height: 'auto', // Adjust the height to be auto to allow for multiple lines
						fontSize: '15px',
						borderRadius: '10px',
						whiteSpace: 'normal', // Enable text wrapping
						wordBreak: 'break-word', // Handle long words
						'& svg': {
							fontSize: 30
						}
					}}
					iconMapping={customIconMapping}
				>
					{message}
				</Alert>
			</Snackbar>
		</Stack>
	) : (
		<></>
	);
}
