import { configureStore } from '@reduxjs/toolkit';
import { searchTermsApi } from './features/searchTerms';
import { resultsApi } from './features/results';
import { userApi } from './features/user';
import { favoritesApi } from './features/favorites';
import { companiesApi } from './features/companies';
import { companyApi } from './features/specificCompany';
import { descriptionsApi } from './features/descriptions';
import navigationReducer from './features/navigation';

const store = configureStore({
  reducer: {
    [searchTermsApi.reducerPath]: searchTermsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [resultsApi.reducerPath]: resultsApi.reducer,
    [favoritesApi.reducerPath]: favoritesApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [descriptionsApi.reducerPath]: descriptionsApi.reducer,
    navigation: navigationReducer, // Add the navigation reducer to the store
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(searchTermsApi.middleware)
      .concat(resultsApi.middleware)
      .concat(favoritesApi.middleware)
      .concat(companiesApi.middleware)
      .concat(companyApi.middleware)
      .concat(descriptionsApi.middleware)
});

export default store;
