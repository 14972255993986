import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const FAQ = ({ question }) => {
	return (
		<Box className="flex justify-between border border-black-100 rounded-lg py-[16px] px-[32px]">
			<Box className="flex items-center gap-x-[16px]">
				<FontAwesomeIcon icon={faCircleQuestion} size="lg" className="text-accent-100" />
				<Typography variant="title3">{question}</Typography>
			</Box>
			<Box>
				<FontAwesomeIcon icon={faChevronDown} size="lg" className="text-accent-100" />
			</Box>
		</Box>
	);
};

export default FAQ;
