import localConfig from "./env.config.local";
import devConfig from "./env.config.dev";
import prodConfig from "./env.config.production";
const env = process.env.NODE_ENV || "development";
let config = {};

switch (env) {
  case "production":
    config = prodConfig;
    break;
  case "development": //devlopment and local are the same for now soon reconfigure
    config = localConfig;
    break;
  default:
    config = devConfig;
}

export default config;
