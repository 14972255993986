import jwtDecode from "jwt-decode";

export const isJwtExpired = (jwt) => {
  try {
    if (jwt) {
      const decodedJwt = jwtDecode(jwt);
      if (decodedJwt?.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        return decodedJwt?.exp < currentTime;
      }
    }
  } catch (error) {
    localStorage.clear();
    return true;
  }
};
