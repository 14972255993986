import { createTheme } from '@mui/material';

const primary = '#3E2989';
const secondary = '#D4CCF0';
const accent = '#5337B9';
const secondaryHover = '#E0D6F5';
const backgroundMain = '#F2EFFA';
const textMain = '#020104';
const textSecondary = '#020104';
const text = '#020104';

const white = '#FFFFFF';
const black = '#020104';
const green = '#197C2F';
const red = '#CD1515';
const grey = '#CCCCCC';

const rootElement = document.getElementById('root');

export const theme = createTheme({
	palette: {
		primary: {
			main: primary
		},
		secondary: {
			main: secondary,
			purple: '#9F94C4',
			light: '#615D70',
			hover: secondaryHover
		},
		accent: {
			main: accent
		},
		background: {
			main: backgroundMain
		},
		text: {
			primary: textMain,
			secondary: textSecondary
		},
		white: {
			main: white
		},
		black: {
			main: black
		},
		green: {
			main: green
		},
		red: {
			main: red
		}
	},
	typography: {
		fontFamily: 'inter',
		h1: {
			fontSize: '72px',
			letterSpacing: 0,
			fontWeight: 700
		},
		h2: {
			fontSize: '51px',
			letterSpacing: 0,
			fontWeight: 500
		},
		h3: {
			fontSize: '36px',
			letterSpacing: 0,
			fontWeight: 400
		},
		h4: {
			fontSize: '25px',
			letterSpacing: 0,
			fontWeight: 400
		},
		h5: {
			fontSize: '18px',
			letterSpacing: 0,
			fontWeight: 600
		},
		h6: {
			fontSize: '16px',
			letterSpacing: '4%',
			fontWeight: 600
		},
		h7: {
			fontSize: '14px',
			letterSpacing: '4%',
			fontWeight: 600
		},
		subtitle1: {
			fontSize: 20,
			fontWeight: 500
		},
		body1: {
			fontSize: 20,
			fontWeight: 400
		},
		body2: {
			fontSize: 18,
			fontWeight: 500
		},
		title1: {
			fontSize: '36px',
			fontWeight: 500,
			letterSpacing: 0
		},
		title2: {
			fontSize: '25px',
			fontWeight: 500,
			letterSpacing: 0
		},
		title3: {
			fontSize: '18px',
			fontWeight: 500,
			letterSpacing: 0
		},
		filteringLink: {
			fontSize: '18px',
			fontWeight: 500,
			letterSpacing: 0
		},
		regularLink: {
			fontSize: '18px',
			fontWeight: 350,
			letterSpacing: 0
		},
		specialLink: {
			fontSize: '14px',
			fontWeight: 500,
			letterSpacing: '4%'
		},
		paragraph: {
			fontSize: '18px',
			lineHeight: '24px',
			fontWeight: 350,
			letterSpacing: '0%'
		},
		boldLink: {
			fontWeight: 700,
			size: '18px'
		},
		textLinks: {
			fontSize: '14px',
			lineHeight: '24px',
			fontWeight: 350,
			letterSpacing: '0%'
		},
		textMuted: {
			fontSize: '14px',
			fontWeight: 500,
			letterSpacing: '0%'
		},
		helperText: {
			fontSize: '14px',
			fontWeight: 500,
			letterSpacing: '0%'
		},
		breadcrumbs: {
			fontSize: '14px',
			lineHeight: '24px',
			fontWeight: 500,
			letterSpacing: '0%'
		},
		micro: {
			fontSize: '10px',
			fontWeight: 350,
			lineHeight: '16px',
			letterSpacing: '0.04em'
		},
		headline3: {
			fontSize: '36px',
			fontWeight: 400,
			lineHeight: '48px'
		},
		headline5: {
			fontSize: '18px',
			fontWeight: 600,
			lineHeight: '24px'
		},
		headline6: {
			fontSize: '16px',
			fontWeight: 500,
			lineHeight: '24px'
		},
		headline7: {
			fontSize: '14px',
			fontWeight: 600,
			lineHeight: '24px',
			letterSpacing: '4%'
		},
		websiteLink: {
			fontSize: '25px',
			fontWeight: 600
		},
		card: {
			fontSize: '14px',
			fontWeight: 700
		}
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							border: '1px solid',
							borderColor: '#CCCCCC',
							color: '#444444'
						},
						'&:hover fieldset': {
							borderColor: secondaryHover
						},
						'&.Mui-focused fieldset': {
							borderColor: accent
						}
					},
					'& .MuiCheckbox-root': {
						color: primary
					},
					'& .MuiInputBase-root': {
						textTransform: 'capitalize',
						height: '60px', // Set the default height for all inputs
						fontSize: '18px',
						fontWeight: 400,
						lineHeight: '21.78px',
						'&.MuiInputBase-multiline': {
							height: 'unset' // Reset height for multi-line inputs
						}
					}
				}
			}
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
					borderRadius: '5px'
				}
			}
		},
		MuiDialog: {
			defaultProps: {
				container: rootElement
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: white,
					borderColor: '#CCCCCC',
					borderRadius: '0px'
				}
			}
		},
		MuiTable: {
			styleOverrides: {
				root: {}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {}
			}
		},
		MuiTableRow: {
			styleOverrides: {
				root: {}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: '18px',
					fontWeight: 700,
					borderRadius: '5px',
					boxShadow: '0px 4px 4px 0px #00000040'
				}
			},
			variants: [
				{
					props: { variant: 'primary' },
					style: {
						background: primary,
						color: white,
						'&:hover': {
							background: accent
						}
					}
				},
				{
					props: { variant: 'secondary' },
					style: {
						background: secondary,
						color: primary,
						'&:hover': {
							background: secondaryHover
						}
					}
				},
				{
					props: { variant: 'active-preference' },
					style: {
						justifyContent: 'center',
						paddingLeft: '16px',
						background: secondary,
						color: primary,
						fontWeight: 350,
						boxShadow: 'none'
					}
				},
				{
					props: { variant: 'inactive-preference' },
					style: {
						justifyContent: 'center',
						paddingLeft: '16px',
						background: white,
						color: text,
						fontWeight: 350,
						boxShadow: 'none',
						'&:hover': {
							background: white
						},
						border: `2px solid ${grey}`
					}
				},
				{
					props: { variant: 'filter' },
					style: {
						background: white,
						color: textMain,
						border: `2px solid ${secondary}`,
						boxShadow: 'none',
						padding: '12px 24px',
						borderRadius: '50px',
						'&:hover': {
							background: secondary,
							color: primary,
							border: `2px solid ${secondary}`
						}
					}
				},
				{
					props: { variant: 'filter-active' },
					style: {
						background: primary,
						color: white,
						border: `2px solid ${primary}`,
						boxShadow: 'none',
						padding: '12px 24px',
						borderRadius: '50px',
						'&:hover': {
							background: primary
						}
					}
				},
				{
					props: { variant: 'button-group' },
					style: {
						fontSize: '15px',
						fontWeight: 500,
						letterSpacing: 0,
						textTransform: 'none',
						boxShadow: 'none'
					}
				},
				{
					props: { variant: 'delete' },
					style: {
						background: secondary,
						color: primary,
						'&:hover': {
							background: red,
							color: white
						}
					}
				}
			]
		}
	}
});
