import { Box, Paper, Typography } from '@mui/material';
import { InputSearch } from '../../UI/Input';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import { useGetCompaniesQuery } from 'features/companies';
import AddDialog from './AddDialog';
import SuccessDialog from './SuccessDialog';
import Notification from 'components/Common/Notification';

export default function Index() {
	const { data, refetch } = useGetCompaniesQuery();
	const [open, setOpen] = useState(false);
	const [results, setResults] = useState();
	const [addDialog, setAddDialog] = useState(false);
	const [successDialog, setSuccessDialog] = useState(false);
	const [newCompanyId, setNewCompanyId] = useState(null);
	const [refreshing, setRefreshing] = useState(false);
	const [initializing, setInitializing] = useState(true);
	const [notification, setNotification] = useState({
		message: '',
		type: '',
		open: false
	});
	const navigate = useNavigate();
	const location = useLocation();

	const handleInput = (value) => {
		setOpen(value ? true : false);
		setResults(() => {
			return data
				?.filter((item) => {
					return item && item.comp_name && item.comp_name.toLowerCase().includes(value);
				})
				.slice(0, 5); // Limiting to at most 5 results
		});
	};

	const handleClick = (item) => {
		navigate(`/admin/companies/${item.comp_id}`);
	};

	const handleOpenAddDialog = () => {
		setAddDialog(true);
	};

	const handleCloseAddDialog = () => {
		setAddDialog(false);
	};

	const handleOpenSuccessDialog = () => {
		setSuccessDialog(true);
	};

	const handleCloseSuccessDialog = () => {
		setSuccessDialog(false);
	};

	const handleViewCompany = () => {
		handleCloseSuccessDialog();
		navigate(`/admin/companies/${newCompanyId}`);
	};

	const handleRefresh = async () => {
		setRefreshing(true);
		try {
			await refetch();
			setNotification({
				message: 'COMPANIES REFRESHED SUCESSFULLY!',
				type: 'success',
				open: true
			});
		} catch (error) {
			setNotification({
				message: `${error}`,
				type: 'error',
				open: true
			});
		}
		setRefreshing(false);
	};

	useEffect(() => {
		if (data && data.length > 0) {
			setInitializing(false);
		}
	}, [data, setInitializing]);

	useEffect(() => {
		if (location.state && location.state.showDeleteSuccessNotification) {
			setNotification({
				message: 'COMPANY DELETED SUCCESSFULLY!',
				type: 'success',
				open: true
			});
			const newState = { ...location.state, showDeleteSuccessNotification: false };
			navigate(location.pathname, { state: newState, replace: true });
		}
	}, [location.state, location.pathname, navigate]);

	return (
		<>
			<Notification
				message={notification.message}
				type={notification.type}
				open={notification.open}
				setOpen={setNotification}
			/>
			<Box className="flex justify-center items-center h-auto w-full my-5 xxl:m-48">
				{addDialog && (
					<AddDialog
						handleCloseAddDialog={handleCloseAddDialog}
						handleOpenSuccessDialog={handleOpenSuccessDialog}
						setNewCompanyId={setNewCompanyId}
					/>
				)}
				{successDialog && (
					<SuccessDialog
						handleCloseSuccessDialog={handleCloseSuccessDialog}
						handleViewCompany={handleViewCompany}
					/>
				)}
				<Box className="flex flex-col justify-center items-center w-full">
					<Header
						handleOpenAddDialog={handleOpenAddDialog}
						handleRefresh={handleRefresh}
						refreshing={refreshing}
					/>
					<Typography className="text-3xl mb-10 text-purple-300 font-medium">Companies</Typography>
					<InputSearch
						placeholder={initializing ? "Loading..." : "Search"}
						sx={{
							'& .MuiInputBase-root': {
								height: '48px',
								fontSize: '18px',
								fontWeight: 400
							}
						}}
						onChange={(event) => {
							handleInput(event.target.value);
						}}
						disabled={refreshing || initializing}
					/>
					{open && (
						<Paper className="flex flex-col w-full">
							{results?.map((item, index) => (
								<Typography
									key={index}
									onClick={() => handleClick(item)}
									variant="paragraph"
									className="cursor-pointer m-[10px] px-[10px] py-[4px]"
								>
									{item.comp_name}
								</Typography>
							))}
						</Paper>
					)}
				</Box>
			</Box>
		</>
	);
}
