import { useState, useEffect } from "react";
import ResultsMobileView from "../Common/ResultsMobileView";

export default function MobileView({ results }) {
  const [transformedData, setTransformedData] = useState();
  useEffect(() => {
    setTransformedData(results);
  }, [results]);

  return (
    <ResultsMobileView
      transformedData={transformedData}
      setTransformedData={setTransformedData}
      results={results}
    />
  );
}
