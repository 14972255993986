import React from 'react';
import DataTableIgnoreList from './DataTableIgnoreList';

export default function Table({ results, selectedSearchResult, setSelectedSearchResult }) {
	const columns = [
		{ title: 'Company Name', key: 'company' }
	];

	return (
		<DataTableIgnoreList
			tableData={results}
			columns={columns}
			selectedSearchResult={selectedSearchResult}
			setSelectedSearchResult={setSelectedSearchResult}
		/>
	);
}
