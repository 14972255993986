import { Paper, Box, Typography, IconButton } from '@mui/material';
import withAuth from '../../helpers/config/Auth/withAuth';
import { InputSearchTerms } from '../UI/Input';
import Button from '../UI/Button';
import { useGetSearchTermsQuery } from '../../features/searchTerms';
import { useGetResultsQuery } from '../../features/results';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SaveSearchTerms from '../../features/api/SaveSearchTerms';
import Notification from '../Common/Notification';
import { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faQuestionCircle,
	faSliders,
	faTrash,
	faAngleDown,
	faAngleUp
	// faCircle
} from '@fortawesome/free-solid-svg-icons';
import { InputText } from '../UI/Input';
// import { stopWords } from './stopWords';

function SearchTerms() {
	const { data, refetch } = useGetSearchTermsQuery();
	const { refetch: refetchResults } = useGetResultsQuery();
	const [saving, setSaving] = useState(false);
	const [reset, setReset] = useState(false);
	const [isFieldValueChanged, setIsFieldValueChanged] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const [notification, setNotification] = useState({
		message: '',
		type: '',
		open: false
	});
	const preferences = ['Daily', 'Weekly', 'None'];
	const [preference, setPreference] = useState('Daily');
	const defaultValue = [
		{
			field: 'field_1',
			state: false,
			state_input_disabled: false,
			value: '',
			extra: false,
			extra_term_1_id: 'field_1_extra_1',
			extra_term1: '',
			extra_term1_state: true,
			extra_term1_input_disabled: true,
			extra_term_2_id: 'field_1_extra_2',
			extra_term2: '',
			extra_term2_state: true,
			extra_term2_input_disabled: true
		},
		{
			field: 'field_2',
			state: true,
			state_input_disabled: true,
			value: '',
			extra: false,
			extra_term_1_id: 'field_2_extra_1',
			extra_term1: '',
			extra_term1_state: true,
			extra_term1_input_disabled: true,
			extra_term_2_id: 'field_2_extra_2',
			extra_term2: '',
			extra_term2_state: true,
			extra_term2_input_disabled: true
		},
		{
			field: 'field_3',
			state: true,
			state_input_disabled: true,
			value: '',
			extra: false,
			extra_term_1_id: 'field_3_extra_1',
			extra_term1: '',
			extra_term1_state: true,
			extra_term1_input_disabled: true,
			extra_term_2_id: 'field_3_extra_2',
			extra_term2: '',
			extra_term2_state: true,
			extra_term2_input_disabled: true
		},
		{
			field: 'field_4',
			state: true,
			state_input_disabled: true,
			value: '',
			extra: false,
			extra_term_1_id: 'field_4_extra_1',
			extra_term1: '',
			extra_term1_state: true,
			extra_term1_input_disabled: true,
			extra_term_2_id: 'field_4_extra_2',
			extra_term2: '',
			extra_term2_state: true,
			extra_term2_input_disabled: true
		},
		{
			field: 'field_5',
			state: true,
			state_input_disabled: true,
			value: '',
			extra: false,
			extra_term_1_id: 'field_5_extra_1',
			extra_term1: '',
			extra_term1_state: true,
			extra_term1_input_disabled: true,
			extra_term_2_id: 'field_5_extra_2',
			extra_term2: '',
			extra_term2_state: true,
			extra_term2_input_disabled: true
		}
	];

	const defaultInitials = {
		field_1: '',
		field_2: '',
		field_3: '',
		field_4: '',
		field_5: '',
		field_1_extra_1: '',
		field_1_extra_2: '',
		field_2_extra_1: '',
		field_2_extra_2: '',
		field_3_extra_1: '',
		field_3_extra_2: '',
		field_4_extra_1: '',
		field_4_extra_2: '',
		field_5_extra_1: '',
		field_5_extra_2: ''
	};

	const [initialValues, setInitialValues] = useState(defaultInitials);
	const [fieldsetCount, setFieldsetCount] = useState(defaultValue);
	const [deliveryPreferences, setDeliveryPreferences] = useState(false);
	const [searchTermErrors, setSearchTermErrors] = useState([]);
	// const statuses = ['Disabled', 'Saved', '', 'Saved', 'Saved'];

	const handleAddTerm = (event) => {
		const name = event.currentTarget.name;
		setFieldsetCount((prevValue) =>
			prevValue.map((item) => {
				if (item?.extra_term_1_id === name) {
					return {
						...item,
						extra_term1_state: false,
						extra_term1_input_disabled: false
					};
				}
				if (item?.extra_term_2_id === name) {
					return {
						...item,
						extra_term2_state: false,
						extra_term2_input_disabled: false
					};
				}
				if (item.field === name) {
					return { ...item, state: false, state_input_disabled: false };
				}
				return item;
			})
		);
	};

	const handleClear = (name, setFieldValue) => {
		setFieldValue(name, '');
	};

	const handleCompCountClick = (event, index) => {
		navigate('/results', { state: { searchTermFieldSelected: index } });
	};

	const handleEditMode = (name, index) => {
		setFieldsetCount((prevValue) =>
			prevValue.map((item) => {
				if (item?.extra_term_1_id === name) {
					return { ...item, extra_term1_input_disabled: false };
				}
				if (item?.extra_term_2_id === name) {
					return { ...item, extra_term2_input_disabled: false };
				}
				if (item.field === name) {
					return { ...item, state_input_disabled: false };
				}
				return item;
			})
		);
	};

	const handleOnChange = (event, setFieldValue) => {
		const name = event.target.name;
		const value = event.target.value;
		setFieldsetCount((prevValue) =>
			prevValue.map((item) => {
				if (item?.extra_term_1_id === name) {
					item.extra_term1 = value;
					setFieldValue(item.extra_term_1_id, value);
					return item;
				}
				if (item?.extra_term_2_id === name) {
					item.extra_term2 = value;
					setFieldValue(item.extra_term_2_id, value);
					return item;
				}
				if (item.field === name) {
					item.value = value;
					setFieldValue(item.field, value);
					return item;
				}
				return item;
			})
		);
		setIsFieldValueChanged(true);
	};

	const preparePayload = (data) => {
		const dataArray = [];

		for (let i = 1; i <= 5; i++) {
			const fieldObj = {};

			const keyMap = {
				[`field_${i}`]: 'searchTerm',
				[`field_${i}_extra_1`]: 'extraTerm1',
				[`field_${i}_extra_2`]: 'extraTerm2'
			};
			for (const key in data) {
				if (key.startsWith(`field_${i}`)) {
					const newKey = keyMap[key] || key;
					fieldObj[newKey] = data[key];
					fieldObj['searchTerm'] = fieldObj['searchTerm'].toUpperCase();
				}
			}

			dataArray.push(fieldObj);
		}

		return dataArray;
	};

	const handleOnSubmit = async (values) => {
		const payload = preparePayload(values);
		setSaving(true);
		try {
			const res = await SaveSearchTerms({ searchTerms: [...payload] });
			if (res.status === 'success') {
				refetch();
				refetchResults();
				setNotification({
					message: 'SEARCH TERM SAVED SUCESSFULLY!',
					type: 'success',
					open: true
				});
				setIsFieldValueChanged(false);
			} else if (res.status === 'partial success') {
				setSearchTermErrors(res.errors);
				setNotification({
					message: res.message,
					type: 'warning',
					open: true
				});
			}
		} catch (error) {
			setNotification({
				message: 'SOMETHING WENT WRONG FROM SERVER!',
				type: 'error',
				open: true
			});
		}
		setSaving(false);
	};

	const handleCancel = (values) => {
		setFieldsetCount(defaultValue);
		setReset((prevValue) => !prevValue);
		setInitialValues(defaultInitials);
		setIsFieldValueChanged(false);
	};
	const handleOnClickPreference = ({ currentTarget }) => setPreference(currentTarget.name);
	const handleOnClickAnd = (event) => {
		const name = event.currentTarget.name;
		setFieldsetCount((prevValue) =>
			prevValue.map((item) => (item.field === name ? { ...item, extra: true } : item))
		);
		setIsFieldValueChanged(true);
	};

	const errorMsg = 'Only alphabetical characters, space, period, and numbers are allowed.';
	const regex = /^[a-zA-Z0-9 .]+$/;

	const validationSchema = Yup.object().shape({
		field_1: Yup.string().matches(regex, errorMsg),
		field_2: Yup.string().matches(regex, errorMsg),
		field_3: Yup.string().matches(regex, errorMsg),
		field_4: Yup.string().matches(regex, errorMsg),
		field_5: Yup.string().matches(regex, errorMsg),
		field_1_extra_1: Yup.string().matches(regex, errorMsg),
		field_1_extra_2: Yup.string().matches(regex, errorMsg),
		field_2_extra_1: Yup.string().matches(regex, errorMsg),
		field_2_extra_2: Yup.string().matches(regex, errorMsg),
		field_3_extra_1: Yup.string().matches(regex, errorMsg),
		field_3_extra_2: Yup.string().matches(regex, errorMsg),
		field_4_extra_1: Yup.string().matches(regex, errorMsg),
		field_4_extra_2: Yup.string().matches(regex, errorMsg),
		field_5_extra_1: Yup.string().matches(regex, errorMsg),
		field_5_extra_2: Yup.string().matches(regex, errorMsg)
	});

	const handleDeliveryPreferencesDropdown = () => {
		//setDeliveryPreferences((deliveryPreferences) => !deliveryPreferences);
		setDeliveryPreferences(false);
	};

	useEffect(() => {
		if (location.state && location.state.showErrorNotificationFromResults) {
			setNotification({
				message: 'PLEASE ADD SEARCH TERM/S FIRST.',
				type: 'warning',
				open: true
			});
			const newState = { ...location.state, showErrorNotificationFromResults: false };
			navigate(location.pathname, { state: newState, replace: true });
		}
	}, [location.state, location.pathname, navigate]);

	useEffect(() => {
		if (data) {
			const newData = fieldsetCount.map((item, index) => {
				const apiItem = data[index];
				if (apiItem) {
					item.combined_search_term = apiItem.combined_search_term;
					item.comp_count = apiItem.comp_count;
					item.extra_term1 = apiItem.extra_term1;
					item.extra_term2 = apiItem.extra_term2;
					item.search_term = apiItem.search_term;
					item.value = apiItem.search_term;
					item.extra = apiItem.extra_term1 !== '' || apiItem.extra_term2 !== '' ? true : false;
					return {
						...item
					};
				} else {
					return {
						...item
					};
				}
			});

			setFieldsetCount(newData);

			const updatedInitialValues = {
				field_1: newData[0]?.value || '',
				field_2: newData[1]?.value || '',
				field_3: newData[2]?.value || '',
				field_4: newData[3]?.value || '',
				field_5: newData[4]?.value || '',
				field_1_extra_1: newData[0]?.extra_term1 || '',
				field_1_extra_2: newData[0]?.extra_term2 || '',
				field_2_extra_1: newData[1]?.extra_term1 || '',
				field_2_extra_2: newData[1]?.extra_term2 || '',
				field_3_extra_1: newData[2]?.extra_term1 || '',
				field_3_extra_2: newData[2]?.extra_term2 || '',
				field_4_extra_1: newData[3]?.extra_term1 || '',
				field_4_extra_2: newData[3]?.extra_term2 || '',
				field_5_extra_1: newData[4]?.extra_term1 || '',
				field_5_extra_2: newData[4]?.extra_term2 || ''
			};
			setInitialValues(updatedInitialValues);
		}
		//eslint-disable-next-line
	}, [data, reset]);

	const handleDeleteRow = (id, setFieldValue) => {
		setFieldsetCount((prevValue) => {
			return prevValue.map((item) => {
				if (item.field === id) {
					return {
						...item,
						extra: false,
						state_input_disabled: true,
						state: true,
						value: '',
						extra_term1: '',
						extra_term1_state: true,
						extra_term1_input_disabled: true,
						extra_term2: '',
						extra_term2_state: true,
						extra_term2_input_disabled: true
					};
				}
				return item;
			});
		});
		setFieldValue(id, '');
		setIsFieldValueChanged(true);
	};

	return (
		<Box className="w-full">
			<Notification
				message={notification.message}
				type={notification.type}
				open={notification.open}
				setOpen={setNotification}
			/>
			<Formik
				enableReinitialize={true}
				validationSchema={validationSchema}
				initialValues={initialValues}
				onSubmit={handleOnSubmit}
			>
				{({ errors, touched, setFieldValue, values }) => (
					<Form>
						<Box className="flex flex-col justify-evenly items-start">
							<Typography className="text-[25px] text-purple-300 font-medium mb-3">
								Search Terms
							</Typography>
							<Paper variant="outlined" className="flex w-full mb-4">
								<Box className="flex flex-col items-start justify-start w-full h-full">
									<Box className="flex flex-col w-full h-auto">
										<Box
											className={`${
												deliveryPreferences && 'border-b'
											} w-full h-[56px] flex items-center justify-between px-[40px] cursor-pointer`}
											onClick={handleDeliveryPreferencesDropdown}
										>
											<Typography className="text-purple-600 select-none" variant="title3">
												Delivery Preferences
											</Typography>
											<FontAwesomeIcon
												icon={deliveryPreferences ? faAngleUp : faAngleDown}
												className="text-purple-200"
											/>
										</Box>
										{deliveryPreferences && (
											<Box className="flex items-end gap-x-[40px] py-[32px] px-[40px] h-full">
												<Box className="w-[40%]">
													<InputText label="Email Address" placeholder="name@domain.com" />
												</Box>
												<Box className="flex gap-x-[24px] w-[60%]">
													{preferences.map((label, index) => (
														<Box className="h-[60px] w-full" key={index}>
															<Button
																className="w-full"
																label={label}
																name={label}
																variant={
																	preference === label ? 'active-preference' : 'inactive-preference'
																}
																onClick={handleOnClickPreference}
															/>
														</Box>
													))}
												</Box>
											</Box>
										)}
									</Box>
								</Box>
							</Paper>
							<Paper variant="outlined" className="flex w-full mb-4">
								<Box className="flex flex-col items-start justify-start w-full h-full">
									<Box className="flex h-[50px] justify-between items-center w-full border-b px-[40px]">
										<Typography className="text-purple-600" variant="title3">
											Terms
										</Typography>
										<FontAwesomeIcon icon={faQuestionCircle} className="text-purple-200" />
									</Box>
									<Box className="flex flex-col justify-center items-start gap-3 xl:gap-5 p-[40px] w-full">
										{data &&
											fieldsetCount.map((item, index) => (
												<Box className="flex justify-between" key={index}>
													<Box className="flex items-end w-full">
														{values[item.field] !== '' ? (
															<Box
																className="flex items-center justify-center h-[48px]"
																onClick={() => handleDeleteRow(item.field, setFieldValue)}
															>
																<IconButton className="p-0 m-0" disableRipple name={item.field}>
																	<FontAwesomeIcon
																		name={item.field}
																		icon={faTrash}
																		className="text-purple-200 w-full hover:text-red-100 pr-4"
																	/>
																</IconButton>
															</Box>
														) : (
															<Box className="w-[40px] xl:w-[55px]"></Box>
														)}
														<Box className="flex flex-col items-end xl:flex-row 2xl:flex-row 3xl:flex-row w-full">
															<Box className="flex flex-col justify-start items-start">
																{index === 0 && (
																	<Typography className="text-grey-500 mb-[24px]" variant="h5">
																		Main Search Terms
																	</Typography>
																)}
																<Box className="flex flex-col">
																	<Field
																		as={InputSearchTerms}
																		name={item.field}
																		fieldDisabled={item.state}
																		handleEditMode={handleEditMode}
																		onChange={(event) => handleOnChange(event, setFieldValue)}
																		handleClear={(event) => handleClear(event, setFieldValue)}
																		placeholder="Add a Search Term"
																		value={values[item.field] || ''}
																		error={Boolean(errors[item.field] && touched[item.field])}
																		helperText={
																			errors[item.field] && touched[item.field]
																				? errors[item.field]
																				: ''
																		}
																		redOutline={searchTermErrors.map(error => error.toUpperCase()).includes((values[item.field] || '').toUpperCase())}
																		// status={statuses[index]}
																	/>
																	{/* {statuses[index] !== 'Disabled' && ( */}
																	<Box
																		className={`flex justify-center text-center translate-y-[50%] translate-x-2 ${
																			isFieldValueChanged ? 'hidden' : 'absolute'
																		} cursor-pointer`}
																		onClick={(event) => handleCompCountClick(event, index)}
																	>
																		<span className={`text-purple-200 hover:text-purple-100`}>
																			{item.comp_count}
																		</span>
																	</Box>
																	{/* )} */}
																</Box>
															</Box>
															<IconButton
																disableRipple
																onClick={handleOnClickAnd}
																name={item.field}
															>
																<FontAwesomeIcon
																	icon={faSliders}
																	className="text-purple-200 w-full pb-1"
																/>
															</IconButton>
															<Box className="flex flex-col mr-[16px]">
																{index === 0 && fieldsetCount.some((item) => item.extra) && (
																	<Typography className="text-grey-500 mb-[24px]" variant="h5">
																		Extra Terms (AND)
																	</Typography>
																)}
																<Box className="flex gap-x-2 h-[48px] w-[376px]">
																	{item.extra && (
																		<>
																			<Field
																				as={InputSearchTerms}
																				name={item.extra_term_1_id}
																				inputDisabled={item.extra_term1_input_disabled}
																				fieldDisabled={item.extra_term1_state}
																				handleAddTerm={handleAddTerm}
																				handleEditMode={handleEditMode}
																				onChange={(event) => handleOnChange(event, setFieldValue)}
																				handleClear={(event) => handleClear(event, setFieldValue)}
																				placeholder=""
																				value={values[item.extra_term_1_id] || ''}
																				error={Boolean(
																					errors[item.extra_term_1_id] &&
																						touched[item.extra_term_1_id]
																				)}
																				helperText={
																					errors[item.extra_term_1_id] &&
																					touched[item.extra_term_1_id]
																						? errors[item.extra_term_1_id]
																						: ''
																				}
																				size="184px"
																				redOutline={searchTermErrors.map(error => error.toUpperCase()).includes((values[item.extra_term_1_id] || '').toUpperCase())}
																			/>
																			<Field
																				as={InputSearchTerms}
																				name={item.extra_term_2_id}
																				inputDisabled={item.extra_term2_input_disabled}
																				fieldDisabled={item.extra_term2_state}
																				handleAddTerm={handleAddTerm}
																				handleEditMode={handleEditMode}
																				onChange={(event) => handleOnChange(event, setFieldValue)}
																				handleClear={(event) => handleClear(event, setFieldValue)}
																				placeholder=""
																				value={values[item.extra_term_2_id] || ''}
																				error={Boolean(
																					errors[item.extra_term_2_id] &&
																						touched[item.extra_term_2_id]
																				)}
																				helperText={
																					errors[item.extra_term_2_id] &&
																					touched[item.extra_term_2_id]
																						? errors[item.extra_term_2_id]
																						: ''
																				}
																				size="184px"
																				redOutline={searchTermErrors.map(error => error.toUpperCase()).includes((values[item.extra_term_2_id] || '').toUpperCase())}
																			/>
																		</>
																	)}
																</Box>
															</Box>
															<Box className="h-[48px] w-[1px] mr-[16px]">
																{/* {item.extra && <Box className="h-full w-[1px] bg-grey-300" />} */}
															</Box>
															<Box className="flex flex-col">
																{index === 0 && fieldsetCount.some((item) => item.extra) && (
																	<Typography className="text-grey-500 mb-[24px]" variant="h5">
																		{/* Exclude Terms (-) */}
																	</Typography>
																)}
																<Box className="flex h-[48px] w-[260px]">
																	{/* {item.extra && (
																		<Field
																			as={InputSearchTerms}
																			// name={item.extra_term_1_id}
																			// inputDisabled={item.extra_term1_input_disabled}
																			// fieldDisabled={item.extra_term1_state}
																			// handleAddTerm={handleAddTerm}
																			handleEditMode={handleEditMode}
																			onChange={(event) => handleOnChange(event, setFieldValue)}
																			handleClear={(event) => handleClear(event, setFieldValue)}
																			placeholder=""
																			value=""
																			// value={values[item.extra_term_1_id] || ''}
																			// error={Boolean(
																			// 	errors[item.extra_term_1_id] &&
																			// 		touched[item.extra_term_1_id]
																			// )}
																			// helperText={
																			// 	errors[item.extra_term_1_id] &&
																			// 	touched[item.extra_term_1_id]
																			// 		? errors[item.extra_term_1_id]
																			// 		: ''
																			// }
																		/>
																	)} */}
																</Box>
															</Box>
														</Box>
													</Box>
													{/* <Box className="flex flex-col ml-[56px]">
														{index === 0 && (
															<Typography className="text-grey-500 mb-[24px]" variant="h5">
																Status
															</Typography>
														)}
														<Box className="flex items-center h-[48px]">
															{statuses[index] !== '' && (
																<FontAwesomeIcon
																	icon={faCircle}
																	className={`${
																		statuses[index] === 'Saved' ? 'text-green-100' : 'text-red-100'
																	} mr-[8px]`}
																/>
															)}
															<Typography className="text-black-100" variant="paragraph">
																{statuses[index]}
															</Typography>
														</Box>
													</Box> */}
												</Box>
											))}
										{isFieldValueChanged && (
											<Box className="flex w-full items-center justify-center mt-[24px]">
												<Box className="flex items-center justify-center w-[250px] xl:w-[300px] h-[45px] xxl:h-[56px] gap-x-[24px]">
													<Button
														type="submit"
														className="text-white-100 text-[16px] xl:text-[18px]"
														label={saving ? 'Saving...' : 'Save'}
														variant="primary"
														disabled={saving}
														width="240px"
													/>
													<Button
														className="text-[16px] xl:text-[18px]ext-[18px]"
														label="Cancel"
														variant="secondary"
														onClick={() => handleCancel(values)}
														width="240px"
													/>
												</Box>
											</Box>
										)}
									</Box>
								</Box>
							</Paper>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
}

export default withAuth(SearchTerms);
