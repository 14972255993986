import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Icons from "../Common/Icons";
import { useDispatch } from "react-redux";
import { toggleNav } from "../../features/navigation";

export default function Drawer() {
  const dispatch = useDispatch();
  const toggleDrawer = (event) => {
    event.stopPropagation();
    dispatch(toggleNav());
  };
  return (
    <Box className="mr-5">
      <IconButton onClick={toggleDrawer}>
        <Icons icon={faBars} className="text-accent-100" />
      </IconButton>
    </Box>
  );
}
