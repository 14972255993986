import DataTableCompanyJobs from './DataTableCompanyJobs';

// Helper function to capitalize the first letter of each word
const capitalizeFirstLetter = (str) => {
    return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};

export default function Table({ results }) {
	// Predefined column
	const predefinedColumns = [{ title: 'Job ID', key: 'native_job_number' }];

	// Extract the first object from the results array
	const firstResult = results && results.length > 0 ? results[0] : null;

	// Dynamically generate columns from the first object's keys, excluding 'native_job_number' to avoid duplication
	const dynamicColumns = firstResult
		? Object.keys(firstResult)
				.filter((key) => key !== 'native_job_number')
				.slice(0, 4) // Slice to ensure we only get 4 additional columns, making a total of 5 with the predefined column
				.map((key) => ({
					title: capitalizeFirstLetter(key), // Format the title
					key
				}))
		: [];

	// Combine predefined columns with dynamic columns
	const columns = [...predefinedColumns, ...dynamicColumns];

	// const columns = [
	//     { title: 'Job ID', key: 'native_job_number' },
	//     { title: 'Job Title', key: 'job_title' },
	//     { title: 'Compensation', key: 'compensation' },
	// 	{ title: 'Employment Type', key: 'employment_type' },
	// 	{ title: 'Location', key: 'location' }
	// ];

	return <DataTableCompanyJobs tableData={results} columns={columns} />;
}
