import CryptoJS from "crypto-js";

function generateCodeVerifier() {
  const rand = new Uint8Array(32);
  crypto.getRandomValues(rand);
  const codeVerifier = base64URL(new CryptoJS.lib.WordArray.init(rand));
  return codeVerifier;
}

function generateCodeChallenge(codeVerifier) {
  const codeChallenge = base64URL(CryptoJS.SHA256(codeVerifier));
  return codeChallenge;
}

function base64URL(buffer) {
  let base64 = CryptoJS.enc.Base64.stringify(buffer);
  base64 = base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
  return base64;
}

export { generateCodeVerifier, generateCodeChallenge };
