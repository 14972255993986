import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCoffee,
  faUser,
  faBars,
  faGear,
  faRightFromBracket,
  faCircleUser,
  faUsersGear,
  faUserPen,
  faPen,
  faSort,
  faPencil,
  faXmark,
  faEnvelopeOpenText,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faSort,
  faCoffee,
  faGear,
  faUser,
  faBars,
  faRightFromBracket,
  faUsersGear,
  faUserPen,
  faPen,
  faCircleUser,
  faPencil,
  faXmark,
  faEnvelopeOpenText,
  faAngleRight
);
